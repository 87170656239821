import clsx from 'clsx';
import styles from './History.module.scss';
import Container from '../Container/Container';
import Timeline from '../Timeline/Timeline';
import { fadeInLeft } from '../../helpers/utils/animations';
import { motion } from 'framer-motion';

const History = () => {
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(styles.section, 'o-hidden')}
        >
            <Container>
                <motion.h2 custom={1} variants={fadeInLeft}>
                    Our <strong>History</strong>
                </motion.h2>
                <motion.p custom={2} variants={fadeInLeft} className={styles.subtitle}>
                    Take a look at the history of how our company was founded and got on its feet
                </motion.p>
                <div className={styles.wrap}>
                    <Timeline />
                </div>
            </Container>
        </motion.section>
    );
};

export default History;
