import { memo, useCallback, useEffect } from 'react';
import { PatternFormat } from 'react-number-format';
import { actionsSelector, configSelector, useCreditRestorationQuizStore } from '../../../../store';
import { useQuizPersonalInfoNameForm } from './hooks/useQuizPersonalInfoNameForm';
import Input from '../../../../../ui/Input/Input';
import s from './QuizPersonalInfoNameForm.module.scss';

const QuizPersonalInfoNameForm = () => {
    const { currentStepNumber } = useCreditRestorationQuizStore(configSelector);
    const quizActions = useCreditRestorationQuizStore(actionsSelector);

    const {
        firstNameController: {
            field: firstNameField,
            fieldState: { error: firstNameError },
        },
        lastNameController: {
            field: lastNameField,
            fieldState: { error: lastNameError },
        },
        emailController: {
            field: emailField,
            fieldState: { error: emailError },
        },
        phoneController: {
            field: phoneField,
            fieldState: { error: phoneError },
        },
    } = useQuizPersonalInfoNameForm();

    const firstNameChangeHandler = useCallback(
        (event) => {
            firstNameField.onChange(event.target.value);
            quizActions.setField('firstName', event.target.value);
        },
        [firstNameField, quizActions]
    );

    const lastNameChangeHandler = useCallback(
        (event) => {
            lastNameField.onChange(event.target.value);
            quizActions.setField('lastName', event.target.value);
        },
        [lastNameField, quizActions]
    );

    const emailChangeHandler = useCallback(
        (event) => {
            emailField.onChange(event.target.value);
            quizActions.setField('email', event.target.value);
        },
        [emailField, quizActions]
    );

    const phoneChangeHandler = useCallback(
        (numberFormatValue) => {
            phoneField.onChange(numberFormatValue.value);
            quizActions.setField('phone', numberFormatValue.value);
        },
        [phoneField, quizActions]
    );

    return (
        <div className={s.wrap}>
            <Input
                label="First Name"
                placeholder="Type your first name"
                required
                error={!!firstNameError?.message}
                helperText={firstNameError?.message}
                {...firstNameField}
                disabled={currentStepNumber === 5}
                autoComplete="off"
                onChange={firstNameChangeHandler}
            />
            <Input
                label="Last Name"
                placeholder="Type your last name"
                required
                error={!!lastNameError?.message}
                helperText={lastNameError?.message}
                {...lastNameField}
                disabled={currentStepNumber === 5}
                autoComplete="off"
                onChange={lastNameChangeHandler}
            />
            <Input
                label="Email"
                placeholder="Type your email"
                type="email"
                error={!!emailError?.message}
                helperText={emailError?.message}
                required
                {...emailField}
                disabled={currentStepNumber === 5}
                autoComplete="off"
                onChange={emailChangeHandler}
            />
            <PatternFormat
                format="(###) ###-####"
                mask="_"
                type="tel"
                label="Phone"
                placeholder="(123) 456-7891"
                error={!!phoneError?.message}
                helperText={phoneError?.message}
                value={phoneField.value}
                customInput={Input}
                disabled={currentStepNumber === 5}
                autoComplete="off"
                onValueChange={phoneChangeHandler}
            />
        </div>
    );
};

export default memo(QuizPersonalInfoNameForm);
