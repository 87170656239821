import clsx from 'clsx';
import Container from '../Container/Container';
import styles from './PostRecent.module.scss';
import PostRecentItem from './PostRecentItem';
import { fadeInLeft, fadeInUp } from '../../helpers/utils/animations';
import { motion } from 'framer-motion';

const PostRecent = ({ articles, className }) => {
    const [item, ...items] = articles;
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(styles.section, 'o-hidden', className)}
        >
            <Container>
                <motion.h2 custom={1} variants={fadeInLeft}>
                    <strong>Recent</strong> blog posts
                </motion.h2>
                <div className={styles.holder}>
                    <motion.div custom={2} variants={fadeInUp} className={styles.left}>
                        <PostRecentItem
                            id={item.id}
                            title={item.title}
                            subtitle={item.subtitle}
                            image={item.image_url}
                            date={item.published_at}
                            chip={item.section}
                            className={styles.item}
                        />
                    </motion.div>
                    <div className={clsx(styles.right, styles.items)}>
                        {items.map((item, i) => (
                            <motion.div custom={i + 1} variants={fadeInUp}>
                                <PostRecentItem
                                    key={item.id}
                                    id={item.id}
                                    title={item.title}
                                    subtitle={item.subtitle}
                                    date={item.published_at}
                                    chip={item.section}
                                />
                            </motion.div>
                        ))}
                    </div>
                </div>
            </Container>
        </motion.section>
    );
};

export default PostRecent;
