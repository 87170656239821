export const STEPS = [
    {
        title: 'Step 1',
        text: 'Complete a simple questionnaire to help us better understand your needs',
    },
    {
        title: 'Step 2',
        text: 'Answer a few simple questions to verify who you are',
    },
    {
        title: 'Step 3',
        text: 'Set up a time to review your personalized options',
    },
];
