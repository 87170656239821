import clsx from 'clsx';
import { motion } from 'framer-motion';
import { RESULTS } from './constants';
import styles from './Presentation.module.scss';
import Container from '../../../../components/Container';
import { fadeInLeft, fadeInRight, fadeInUp } from '../../../../helpers/utils/animations';
import ButtonFollow from '../../../../components/ui/ButtonFollow/ButtonFollow';

const Presentation = ({ executeScroll }) => {
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(styles.presentation, 'o-hidden')}
        >
            <Container>
                <div className={styles.holder}>
                    <div className={styles.left}>
                        <motion.h2 custom={1} variants={fadeInLeft}>
                            <strong>Are you eligible</strong> for medical debt relief?
                        </motion.h2>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className={styles.values}
                        >
                            {RESULTS.map((item, i) => (
                                <motion.div custom={i + 1} variants={fadeInUp} key={i} className={styles.number}>
                                    <p className={styles.quantity}>
                                        {item.num}
                                        {item?.customLabel ? <span>{item.customLabel}</span> : null}
                                        {!item?.customLabel && item.star && (
                                            <span>
                                                <img src="./images/blue-star.svg" alt="star" />
                                            </span>
                                        )}
                                    </p>
                                    <p className={styles.helperText}>{item.text}</p>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.rightContent}>
                            <motion.h2 custom={1} variants={fadeInRight}>
                                See how Triton can <strong>change your life for the better</strong>
                            </motion.h2>
                            <motion.p custom={2} variants={fadeInRight}>
                                Watch a real client&apos;s success story after our expert advisors helped reduce their
                                inflated hospital bills to next to nothing
                            </motion.p>
                            <motion.div custom={3} variants={fadeInRight}>
                                <ButtonFollow onClick={executeScroll} reverse>
                                    Read Article
                                </ButtonFollow>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </Container>
        </motion.section>
    );
};

export default Presentation;
