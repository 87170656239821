import { memo, useState } from 'react';
import { clsx } from 'clsx';
import AccordionItem from './AccordionItem';
import styles from './Accordion.module.scss';

const Accordion = ({ list, additionalClasses, activeId }) => {
    const [expanded, setExpanded] = useState(activeId);

    return (
        <div className={clsx(styles.wrap, additionalClasses)}>
            {list.map((item) => {
                const isOpen = item.id === expanded;

                return (
                    <AccordionItem
                        key={item.id}
                        title={item.title}
                        desc={item.info}
                        id={item.id}
                        isOpen={isOpen}
                        setExpanded={setExpanded}
                    />
                );
            })}
        </div>
    );
};

export default memo(Accordion);
