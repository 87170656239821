import React from 'react';
import ButtonsGroup from '../../components/ButtonsGroup/ButtonsGroup';
import styles from './AgreementsPage.module.scss';

const Policy = () => {
    return (
        <div className={styles.content}>
            <h1 className="h1">Privacy Policy</h1>
            <h4 className="h4">Effective Date: January 1st, 2023</h4>
            <h3 className="h3">Introduction</h3>

            <p>
                Triton Finance ("we," "us," or "our") is committed to protecting the privacy and security of the
                personal information of our website visitors ("users," "you," or "your"). This privacy policy outlines
                the types of information we collect, how we use and protect that information, and your rights regarding
                your personal information. By using our website, you consent to the practices described in this policy.
            </p>

            <h3 className="h3">Information We Collect</h3>
            <p>
                a. Personal Information: We may collect personal information, such as your name, contact details
                (including email address and phone number), and any other information you voluntarily provide to us
                through our website's contact forms or other means of communication.
                <br />
                b. Financial Information: In the course of providing debt consolidation services, we may collect
                financial information, including credit card details and other sensitive data necessary for processing
                payments and facilitating consumer debt consulting services. We only collect this information when
                explicitly provided by you.
                <br />
                c. Usage Data: We may automatically collect certain information about your visit to our website,
                including your IP address, browser type, operating system, referring URLs, and pages visited. This
                information helps us analyze trends, administer the website, and improve user experience.
            </p>
            <br />

            <h3 className="h3">Use of Information</h3>
            <p>
                a. Personalization: We may use your personal information to personalize your experience on our website,
                such as tailoring content and recommendations based on your preferences and interests.
                <br />
                b. Communication: We may use your contact information to respond to your inquiries, provide updates
                regarding our services, and communicate with you about relevant offers or promotions. You can opt-out of
                receiving marketing communications at any time.
                <br />
                c. Debt Consolidation Services: If you engage our debt consolidation services, we will use the
                information you provide to facilitate negotiations with creditors, process payments, and match you with
                potential lenders.
                <br />
                d. Legal Compliance: We may use your information to comply with applicable laws, regulations, legal
                processes, or enforceable governmental requests.
            </p>

            <h3 className="h3">Information Sharing</h3>
            <p>
                a. Third-Party Service Providers: We may share your information with trusted third-party service
                providers who assist us in operating our website, delivering services, or conducting business
                operations. These providers have access to the necessary information only to perform their tasks on our
                behalf and are obligated to protect your information.
                <br />
                b. Business Transfers: In the event of a merger, acquisition, or sale of our business, your personal
                information may be transferred to the acquiring entity or its agents as part of the transaction. We will
                notify you of any such transfer and provide you with choices regarding your information.
                <br />
                c. Legal Requirements: We may disclose your information if required by law, legal process, or regulatory
                authorities, or to protect our rights, property, or safety, as well as the rights, property, or safety
                of others.
            </p>

            <h3 className="h3">Data Security</h3>
            <p>
                We implement reasonable security measures to protect your personal information from unauthorized access,
                loss, misuse, or alteration. However, no method of transmission or storage is completely secure. We
                cannot guarantee the absolute security of your information.
            </p>

            <h3>Data Retention</h3>

            <p>
                We retain personal information for as long as necessary to fulfill the purposes outlined in this privacy
                policy, unless a longer retention period is required or permitted by law.
            </p>

            <h3 className="h3">Your Rights</h3>
            <p>
                a. Access and Correction: You have the right to access and update your personal information held by us.
                You can do so by contacting us using the information provided in Section 9.
                <br />
                b. Consent Withdrawal: You have the right to withdraw your consent to the processing of your personal
                information at any time, except where we are legally required to retain certain information.
                <br />
                c. Opt-Out: You may opt-out of receiving marketing communications from us by following the unsubscribe
                instructions provided in the communication or contacting us directly.
            </p>

            <h3 className="h3">Third-Party Websites</h3>
            <p>
                Our website may contain links to third-party websites or services. This privacy policy does not apply to
                those websites or services, and we are not responsible for their privacy practices. We encourage you to
                review the privacy policies of any third-party websites you visit.
            </p>

            <h3 className="h3">Contact Us</h3>
            <p>
                If you have any questions, concerns, or requests regarding this privacy policy or the handling of your
                personal information, please contact us at:
                <br />
            </p>
            <p></p>

            <p>
                Triton Consulting LLC
                <br />
                Address: 3500 S Dupont Hwy, Dover, DE 19901
                <br />
                Email: info@triton.finance
            </p>
            <p></p>
            <p>We will respond to your inquiries and address your concerns in a timely manner.</p>
            <h3>Changes to the Privacy Policy</h3>

            <p>
                We reserve the right to update or modify this privacy policy at any time. The updated version will be
                indicated by an updated "Effective Date" at the beginning of this policy. We encourage you to review
                this policy periodically to stay informed of our privacy practices.
            </p>
            <p></p>

            <p>By using our website, you signify your acceptance of this privacy policy.</p>
            <ButtonsGroup />
        </div>
    );
};

export default Policy;
