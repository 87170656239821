import { useEffect, useRef, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import s from './Search.module.scss';
import { AppContext } from '../../App';

const Search = () => {
    const { articles } = useContext(AppContext);
    const [inputValue, setInputValue] = useState('');
    const [focused, setFocused] = useState(false);
    const inputRef = useRef(null);
    const componentRef = useRef(null);

    const onFocus = () => setFocused(true);

    useEffect(() => {
        const handleClick = (e) => {
            if (componentRef && componentRef.current) {
                const ref = componentRef.current;
                if (!ref.contains(e.target)) {
                    setInputValue('');
                }
            }
        };

        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearHandler = () => {
        setInputValue('');

        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const searchInputChangeHandler = (event) => {
        event.preventDefault();

        const inputValue = event.target.value.toLowerCase().trimStart();

        setInputValue(inputValue);
    };

    const removeQuery = () => {
        inputRef.current.blur();
        clearHandler();
    };

    const visibleArticles = articles.filter((article) => article.title.toLowerCase().includes(inputValue));

    return (
        <div className={s.root} ref={componentRef}>
            <input
                value={inputValue}
                onChange={searchInputChangeHandler}
                type="text"
                placeholder="Search"
                ref={inputRef}
                onFocus={onFocus}
                className={`${inputValue.length > 0 && s.inputActive}`}
            />

            <svg
                className={`${focused && s.iconFocused} ${s.icon}`}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.78904 9.58429C6.80134 11.1763 3.89177 11.051 2.04918 9.20839C0.072149 7.23136 0.072149 4.02596 2.04918 2.04893C4.02621 0.0719047 7.2316 0.071905 9.20863 2.04893C11.0512 3.89152 11.1765 6.80109 9.58454 8.78879L13.4513 12.6555C13.6709 12.8752 13.6709 13.2313 13.4513 13.451C13.2316 13.6707 12.8754 13.6707 12.6558 13.451L8.78904 9.58429ZM2.84467 8.4129C1.30698 6.87521 1.30698 4.38212 2.84467 2.84443C4.38236 1.30674 6.87545 1.30674 8.41314 2.84443C9.9497 4.38099 9.95083 6.87155 8.41652 8.40951C8.41539 8.41062 8.41426 8.41175 8.41313 8.41288C8.412 8.414 8.41087 8.41514 8.40976 8.41627C6.8718 9.95058 4.38124 9.94946 2.84467 8.4129Z"
                    fill="white"
                    fillOpacity="0.7"
                />
            </svg>
            {inputValue && (
                <div className={s.clearButton} onClick={removeQuery}>
                    Clear
                </div>
            )}

            {inputValue && (
                <div className={s.articles}>
                    {visibleArticles.length > 0 ? (
                        visibleArticles.slice(0, 5).map((item) => (
                            <Link to={`/blog/${item.id}`} key={item.id}>
                                <div className={s.singleArticle} tabIndex="0">
                                    <img src={item.image} alt={item.title} />
                                    <div className={s.text}>
                                        <p>{item.title}</p>
                                        <span>{item.date}</span>
                                    </div>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <p className={s.nothing}>Nothing found</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Search;
