import { memo } from 'react';
import styles from './LoadingBlock.module.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '../Modal/Modal';

const LoadingBlock = ({ opened }) => {
    return (
        <Modal opened={opened} close={null} noWrap={true} additionalClasses={styles.container}>
            <CircularProgress style={{ color: '#4C76EB' }} size="8em" />
        </Modal>
    );
};

export default memo(LoadingBlock);
