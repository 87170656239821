import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { actionsSelector, personalInfoStepSelector, useCreditRestorationQuizStore } from '../../../../../store';
import { quizPersonalInfoNameSchema } from '../schemas';

export const useQuizPersonalInfoNameForm = () => {
    const { firstName, lastName, email, phone } = useCreditRestorationQuizStore(personalInfoStepSelector);
    const quizActions = useCreditRestorationQuizStore(actionsSelector);

    const {
        control,
        formState: { isValid },
        ...rest
    } = useForm({
        defaultValues: {
            firstName,
            lastName,
            email,
            phone,
        },
        resolver: zodResolver(quizPersonalInfoNameSchema),
        mode: 'onChange',
    });

    const firstNameController = useController({
        control,
        name: 'firstName',
    });
    const lastNameController = useController({
        control,
        name: 'lastName',
    });
    const emailController = useController({
        control,
        name: 'email',
    });
    const phoneController = useController({
        control,
        name: 'phone',
    });

    useEffect(() => {
        quizActions.setField('isNameDataStepValid', isValid);
    }, [isValid, quizActions]);

    return {
        firstNameController,
        lastNameController,
        emailController,
        phoneController,
        ...rest,
    };
};
