const IconCaret = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <path
                fillRule="evenodd"
                d="M18.707 9.96a1 1 0 0 1 0 1.414L14.08 16l4.626 4.626a1 1 0 1 1-1.414 1.414l-5.334-5.333a1 1 0 0 1 0-1.414l5.334-5.333a1 1 0 0 1 1.414 0Z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconCaret;
