import Cookies from 'js-cookie';
import {
    AFFILIATE_MARKETING_ID_PREFIX,
    COOKIE_MARKETING_ID,
    COOKIE_AFFILIATE_ID,
    EXTRACT_NUMBER_REGEX,
    PARTNER_MARKETING_ID_PREFIX,
    SPONSOR_MARKETING_ID_PREFIX,
} from '../../../constants/constants';

export const getMarketingId = () => {
    const subIdFromLocalStorage = localStorage.getItem(COOKIE_MARKETING_ID);
    const subIdFromCookies = Cookies.get(COOKIE_MARKETING_ID);

    return subIdFromLocalStorage || subIdFromCookies || '';
};

export const getIfDHLikeSubId = () => {
    const subId = getMarketingId();

    if (!subId) {
        return false;
    }

    const isSubIdEqualsToDH = subId === 'dh';
    const isSubIdStartsWithDH =
        subId.startsWith('dh-') || subId.startsWith('DH-') || subId.startsWith('dh_') || subId.startsWith('DH_');

    return isSubIdEqualsToDH || isSubIdStartsWithDH;
};

export const getIfReferralAffiliateSubId = () => {
    const subId = getMarketingId();

    if (!subId) {
        return false;
    }

    const isSubIdStartWithSponsorPrefix = subId.startsWith(SPONSOR_MARKETING_ID_PREFIX);
    const isSubIdStartWithPartnerPrefix = subId.startsWith(PARTNER_MARKETING_ID_PREFIX);
    const isSubIdStartWithAffiliatePrefix = subId.startsWith(AFFILIATE_MARKETING_ID_PREFIX);

    return isSubIdStartWithSponsorPrefix || isSubIdStartWithPartnerPrefix || isSubIdStartWithAffiliatePrefix;
};

export const getAffiliateId = () => {
    const affiliateIdFromLocalStorage = localStorage.getItem(COOKIE_AFFILIATE_ID);
    const affiliateIdFromCookies = Cookies.get(COOKIE_AFFILIATE_ID);

    return affiliateIdFromLocalStorage || affiliateIdFromCookies || '';
};

export const parseReferralAffiliateSubId = (id) => {
    if (!id) {
        return '';
    }

    const isReferralAffiliateSubId = id.startsWith(AFFILIATE_MARKETING_ID_PREFIX);

    if (!isReferralAffiliateSubId) {
        return '';
    }

    return id.replace(EXTRACT_NUMBER_REGEX, '');
};
