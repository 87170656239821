import { z } from 'zod';
import { stringSchema } from '../../../../../../helpers/utils/validation';

const ZIP_INVALID_ERROR = 'Zip code has to be 5 digits long';

const zipSchema = z.string().refine(
    (value) => {
        const isInteger = Number.isInteger(Number(value));
        const isFiveDigit = value.length === 5;

        return isInteger && isFiveDigit;
    },
    {
        message: ZIP_INVALID_ERROR,
    }
);

export const quizPersonalInfoAddressSchema = z.object({
    state: stringSchema,
    city: stringSchema,
    address: stringSchema,
    zip: zipSchema,
});
