import { motion } from 'framer-motion';
import clsx from 'clsx';
import Container from '../Container';
import LinkButton from '../LinkButton';
import { ROUTES } from '../../constants/constants';
import { SOLVE_ITEMS } from './constants';
import { fadeInLeft, fadeInUp } from '../../helpers/utils/animations';
import s from './SolveProblems.module.scss';

const SolveProblems = () => {
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(s.section, 'o-hidden')}
        >
            <Container>
                <motion.h2 custom={1} variants={fadeInLeft}>
                    What is <strong>our approach?</strong>
                </motion.h2>
                <motion.h5 custom={2} variants={fadeInLeft} className="h2-subtitle">
                    Our company helps to solve not only problems with debt consolidation, but also many other problems
                    associated with financial relationships
                </motion.h5>

                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
                    <div className={s.holder}>
                        {SOLVE_ITEMS.map((item, i) => (
                            <motion.div custom={i + 1} variants={fadeInUp} key={i}>
                                <div className={s.item}>
                                    <picture className={s.image}>
                                        <source srcSet={item.imageMob} media="(max-width: 479px)" />
                                        <img src={item.image} alt={`${item.title} ${item.titleBold}`} />
                                    </picture>
                                    <div className={s.content}>
                                        <h3>
                                            {item.title} <strong>{item.titleBold}</strong>
                                        </h3>
                                        <p className={s.desc}>{item.desc}</p>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                        <motion.div custom={SOLVE_ITEMS.length + 1} variants={fadeInUp} className={s.card}>
                            <div className={s.cardTitle}>
                                <h2>Ready To Pay Off Your Debt?</h2>
                            </div>
                            <p className={s.cardSubtitle}>
                                Let us help you find the smartest debt solution. No hassle. No credit impact
                            </p>
                            <div className={s.cardAction}>
                                <LinkButton
                                    linkTo={ROUTES.quiz}
                                    responsive={false}
                                    variant="fourth"
                                    hasIcon
                                    blueBlock
                                />
                            </div>
                            <div className={clsx(s.cardImage, s.image)}>
                                <img src="./images/svg/coins.svg" alt="Ready To Pay Off Your Debt?" />
                            </div>
                        </motion.div>
                    </div>
                </motion.div>
            </Container>
        </motion.section>
    );
};

export default SolveProblems;
