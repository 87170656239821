import { devtools } from 'zustand/middleware';

/**
 *
 * @param {string} storeName
 * @param {any} fn
 * @returns
 */
export const middlewares = (storeName, fn) => {
    return devtools(fn, { name: storeName, serialize: { options: true } });
};
