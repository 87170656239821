import { ROUTES } from '../../constants/constants';

export const CREDIT_RESTORATION_QUIZ_STEPS = {
    [ROUTES.creditScoreInterest]: {
        step: 1,
        step_name: ROUTES.creditScoreInterest,
        label: 'Understanding Your Needs',
    },
    [ROUTES.understandingNeeds]: {
        step: 2,
        step_name: ROUTES.understandingNeeds,
        label: 'Understanding Your Needs',
    },
    [ROUTES.timeline]: {
        step: 3,
        step_name: ROUTES.timeline,
        label: 'Timeline',
    },
    [ROUTES.personalInfo]: {
        step: 4,
        step_name: ROUTES.personalInfo,
        label: 'Contact details',
    },
    [ROUTES.address]: {
        step: 5,
        step_name: ROUTES.address,
        label: 'Contact details',
    },
    [ROUTES.reviewOptions]: {
        step: 6,
        step_name: ROUTES.personalInfo,
        label: 'Review Your Options',
    },
};

export const CREDIT_RESTORATION_QUIZ_TIMELINE_OPTIONS = ['As soon as possible', 'I have time', 'Not in any rush'];
