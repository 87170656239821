export const CREDIT_SCORE_SOLUTIONS_ACCORDION_ITEMS = [
    {
        id: 1,
        title: 'Does paying a collection hurt my credit score?',
        info: 'This is one of the most common misconceptions in the industry but we are happy to clear the air! Keep in mind, every situation will vary because no two credit reports are the same. Paying a collection, more often than not, can hurt your credit score and here is why. When you pay on a collection, it resets the Date of Last Activity (DLA) which means, it will update the account to look as though it is a recent collection and only update the amount past due to a lower balance. This does not remove the collection from your credit report and usually does more harm than good.',
    },
    {
        id: 2,
        title: 'Should I close a credit card account that I don`t use?',
        info: 'Not recommended. When you close a credit card account it can affect your average length of credit history, which can negatively impact your scores.',
    },
    {
        id: 3,
        title: 'Does checking my own credit score hurt my credit?',
        info: 'No. As a consumer you have access to multiple websites that will allow you to check your credit report and scores without it hurting you. Call or email us for a free consultation and recommendations!',
    },
    {
        id: 4,
        title: 'How long does credit repair take?',
        info: 'Every credit report is completely different and length of time varies on a few different factors. Call us today for your free consultation!',
    },
];

export const CREDIT_SCORE_SOLUTIONS_REVIEWS = [
    {
        id: 's1',
        rating: 5,
        text: "After months of feeling overwhelmed by my credit situation, Triton`s personalized approach made all the difference. I'm not only on the path to recovery; I've also gained invaluable financial literacy.",
        name: 'Jessica',
        shortName: 'J',
        date: '3 hours ago',
    },
    {
        id: 's2',
        rating: 5,
        text: "Triton turned my skepticism into belief. Each step of the credit repair process was transparent and effective, leading to results I didn't think were possible. My credit score is now a source of pride!",
        name: 'Arnold',
        shortName: 'A',
        date: '1 day ago',
        customColor: '#DF8B0E',
        customBg: 'rgba(223, 139, 14, 0.1)',
    },
    {
        id: 's3',
        rating: 5,
        text: 'The team at Triton treated my case with the utmost care and urgency. Their expertise in handling disputable items on my report was remarkable, significantly boosting my credit score in just a few months.',
        name: 'Robert',
        shortName: 'R',
        date: '2 hours ago',
        customColor: '#98DB96',
        customBg: 'rgba(152, 219, 150, 0.1)',
    },
];
