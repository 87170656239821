const IconPlay = (props) => {
    return (
        <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" width="44" height="44" rx="22" fill="none" />
            <path
                d="M27.3441 23.0108C28.0053 22.4992 28.0053 21.5009 27.3441 20.9892C25.3457 19.4429 23.1142 18.2242 20.7332 17.3785L20.2982 17.224C19.466 16.9285 18.587 17.4915 18.4744 18.3505C18.1596 20.7507 18.1596 23.2493 18.4744 25.6495C18.587 26.5085 19.466 27.0716 20.2982 26.776L20.7332 26.6215C23.1142 25.7759 25.3457 24.5571 27.3441 23.0108Z"
                fill="white"
            />
        </svg>
    );
};

export default IconPlay;
