import { memo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import styles from './Accordion.module.scss';

const DEFAULT_TRANSITION = { type: 'linear', duration: 0.3 };

const AccordionItem = ({ title, desc, id, isOpen, setExpanded }) => {
    const setExpandedHandler = () => {
        setExpanded(isOpen ? false : id);
    };

    const setExpandedByKeyboardHandler = (e) => {
        if (e.key === 'Enter') {
            setExpandedHandler();
        }
    };

    const preventClickHandler = (e) => {
        e.stopPropagation();
    };

    <div className={styles.subtitle} />;

    return (
        <div className={clsx(styles.item, isOpen && styles.active)}>
            <div
                className={styles.inner}
                tabIndex={0}
                role="button"
                onKeyDown={setExpandedByKeyboardHandler}
                onClick={setExpandedHandler}
            >
                <div className={styles.header}>
                    <h4>{title}</h4>
                    <svg className={styles.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none">
                        <path
                            fill="#B8BABE"
                            fillRule="evenodd"
                            d="M20.663 12.462a.937.937 0 0 0-1.326 0L15 16.8l-4.337-4.337a.938.938 0 0 0-1.326 1.326l5 5a.937.937 0 0 0 1.326 0l5-5a.937.937 0 0 0 0-1.326Z"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            className="o-hidden"
                            initial={{ height: 0 }}
                            animate={{
                                height: 'auto',
                                transition: DEFAULT_TRANSITION,
                            }}
                            exit={{
                                height: 0,
                                transition: DEFAULT_TRANSITION,
                            }}
                            onClick={preventClickHandler}
                        >
                            <div className={styles.holder}>
                                <p
                                    className={styles.content}
                                    dangerouslySetInnerHTML={{ __html: desc.replace(/\n/g, '<br />') }}
                                />
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default memo(AccordionItem);
