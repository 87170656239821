import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import ArticleSection from '../../components/Article/ArticleSection';
import useWindowDimensions from '../../helpers/hooks/useWindowDimensions';
import CalculatorSection from '../../components/CalculatorSection/CalculatorSection';
import BlogHero from '../../components/BlogHero/BlogHero';
import { formattedDate } from '../../helpers/utils/utils';
import PostRecent from '../../components/PostRecent/PostRecent';
import { ROUTES } from '../../constants/constants';

const Blog = () => {
    const { articles } = useContext(AppContext);
    const [articlesCount, setArticlesCount] = useState(9);
    const { screenWidth } = useWindowDimensions();
    const [topArticle, setTopArticle] = useState(0);

    useEffect(() => {
        if (screenWidth < 650) {
            setArticlesCount(3);
        }
    }, [screenWidth]);

    const changeArticlesHandler = () => {
        setArticlesCount((prevArticles) => {
            if (screenWidth < 650) {
                return prevArticles + 1;
            }
            return prevArticles + 3;
        });
    };

    const recentArticles = [...articles].sort((a, b) => b.published_at - a.published_at).slice(0, 3);

    useEffect(() => {
        setTopArticle(Math.floor(Math.random() * articles.length));
    }, [articles]);

    return (
        <>
            <BlogHero
                href={`${ROUTES.blog}/${articles[topArticle]?.id}`}
                image={articles[topArticle]?.image_url}
                title={articles[topArticle]?.title}
                date={formattedDate(articles[topArticle]?.published_at, false)}
            />
            {Boolean(recentArticles.length > 2) && <PostRecent articles={recentArticles.slice(0, 3)} />}

            <ArticleSection
                titleBold="All"
                titleRest="blog posts"
                numberOfArticles={articlesCount}
                linkName="Show more"
                clickHandler={changeArticlesHandler}
                articles={articles}
                variant="secondary"
            />

            <CalculatorSection subtitle="Let us help you get approved for the smartest debt solution from a nationwide network of lenders. No hassle. No credit impact." />
        </>
    );
};

export default Blog;
