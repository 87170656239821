const ArrowCircleRightTop = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <path
                fill="currentColor"
                d="M7.2 7.2c-4.857 4.858-4.857 12.742 0 17.6 4.858 4.857 12.742 4.857 17.6 0 4.857-4.858 4.857-12.742 0-17.6-4.858-4.857-12.742-4.857-17.6 0Zm12.813 5.72v5.28a.923.923 0 0 1-.273.66.923.923 0 0 1-.66.273.939.939 0 0 1-.933-.933v-3.027L13.58 19.74a.94.94 0 0 1-1.32 0 .94.94 0 0 1 0-1.32l4.567-4.567H13.8a.939.939 0 0 1-.933-.933c0-.51.423-.933.933-.933h5.28c.51 0 .933.423.933.933Z"
            />
        </svg>
    );
};

export default ArrowCircleRightTop;
