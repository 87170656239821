import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid } from 'recharts';
import { useMediaQuery } from '../../../../../../../hooks/useMediaQuery';
import {
    CHART_DEFAULT_HEIGHT,
    CHART_DEFAULT_MARGIN,
    CHART_EMPTY_CELL,
    CHART_AXIS_COLOR,
    CHART_VALUES_DOMAIN,
    CHART_AXIS_DEFAULT_TICKS_AMOUNT,
} from './constants';

const ProgramResultChart = ({ className, data, chartLineColor }) => {
    const chartRef = useRef(null);

    const isMobile = useMediaQuery('(max-width: 768px)');

    const getChartWidth = useCallback(() => {
        if (chartRef.current && chartRef.current?.props) {
            return chartRef.current?.props?.width ?? 0;
        }

        return 0;
    }, []);
    const [gridWidth, setGridWidth] = useState();
    const [isRendered, setIsRendered] = useState(false);

    const calculateGridColumnWidth = useCallback(() => {
        setGridWidth(getChartWidth());
    }, [getChartWidth]);

    const memoizedLineTranslateXValue = useMemo(() => {
        if (!gridWidth) {
            calculateGridColumnWidth();

            return 0;
        }

        const singleGridColumnWidth = gridWidth / (data.length - 1);

        const result = singleGridColumnWidth / 2;

        if (result === Infinity || isNaN(result)) {
            return 0;
        }

        return result;
    }, [calculateGridColumnWidth, data.length, gridWidth]);

    useEffect(() => {
        setIsRendered(true);

        if (chartRef.current) {
            calculateGridColumnWidth();
        }
    }, [calculateGridColumnWidth]);

    useLayoutEffect(() => {
        if (isRendered) {
            calculateGridColumnWidth();
        }

        window.addEventListener('resize', calculateGridColumnWidth);

        return () => {
            window.removeEventListener('resize', calculateGridColumnWidth);
        };
    }, [calculateGridColumnWidth, gridWidth, isRendered]);

    return (
        <ResponsiveContainer className={className} width="100%" height="100%">
            <LineChart
                ref={chartRef}
                height={CHART_DEFAULT_HEIGHT}
                data={[...data, CHART_EMPTY_CELL]}
                margin={{
                    top: CHART_DEFAULT_MARGIN,
                    right: CHART_DEFAULT_MARGIN,
                    bottom: CHART_DEFAULT_MARGIN,
                }}
            >
                <CartesianGrid vertical={false} />
                <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={({ x, y, payload, dx }) => {
                        return (
                            <g
                                transform={`translate(${
                                    x + (isMobile ? 0 : memoizedLineTranslateXValue) / 2
                                },${y + CHART_DEFAULT_MARGIN})`}
                            >
                                <text dx={dx} dy={7}>
                                    {payload.value}
                                </text>
                            </g>
                        );
                    }}
                />
                <YAxis
                    tickCount={CHART_AXIS_DEFAULT_TICKS_AMOUNT}
                    axisLine={false}
                    tickLine={false}
                    domain={CHART_VALUES_DOMAIN}
                    fill={CHART_AXIS_COLOR}
                    tick={({ payload, x, y }) => {
                        return (
                            <g transform={`translate(${x - 20},${y})`}>
                                <text dx={-8} dy={4} fill={CHART_AXIS_COLOR} fontSize={14}>
                                    {payload.value}
                                </text>
                            </g>
                        );
                    }}
                />
                <Line
                    dataKey="value"
                    stroke={chartLineColor}
                    strokeWidth={2}
                    transform={`translate(${memoizedLineTranslateXValue - 16} 0)`}
                    activeDot={{
                        transform: `translate(${memoizedLineTranslateXValue - 16} 0)`,
                    }}
                    dot={{
                        strokeWidth: 1,
                        fill: chartLineColor,
                    }}
                    tr
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

ProgramResultChart.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.number,
        })
    ),
    chartLineColor: PropTypes.string,
};

export default memo(ProgramResultChart);
