import { ROUTES } from '../../constants/constants';

export const QUIZ_STEP_DELAY = 500;

export const QUIZ_STEP_CURRENT = 0;
export const QUIZ_STEP_INACTIVE = -1;

export const STEPS_COUNT = {
    [ROUTES.understandingNeeds]: {
        step: 1,
        step_name: ROUTES.understandingNeeds,
        label: 'Understanding Your Needs',
    },
    [ROUTES.personalInfo]: {
        step: 2,
        step_name: ROUTES.personalInfo,
        label: "Help Us Make Sure It's You",
    },
    [ROUTES.reviewOptions]: {
        step: 3,
        step_name: ROUTES.reviewOptions,
        label: 'Review Your Options',
    },
};

export const STEPS_COUNT_PANE = {
    [ROUTES.understandingNeeds]: 5,
    [ROUTES.personalInfo]: 2,
    [ROUTES.reviewOptions]: 0,
};

export const STEP_COUNT_PANE_WITH_ANNUAL_INCOME = {
    [ROUTES.understandingNeeds]: 6,
    [ROUTES.personalInfo]: 2,
    [ROUTES.reviewOptions]: 0,
};

export const STEPS_TITLE_ITEMS = [
    {
        title: 'Step',
        text: 'Complete a simple questionnaire to help us better understand your needs',
    },
    {
        title: 'Step',
        text: 'Answer a few simple questions to verify who you are',
    },
    {
        title: 'Step',
        text: 'Get personalized options to help pay off your debt',
    },
];

export const QUIZ_GOAL_TYPES = {
    improveCredit: {
        value: 'Improve credit',
        label: 'Improve credit',
    },
    payMyDebt: {
        value: 'Pay off my debt',
        label: 'Pay off my debt',
    },
    both: {
        value: 'Both',
        label: 'Both',
    },
};

export const QUIZ_DEBT_TYPES = {
    creditCard: {
        value: 'Credit card',
        label: 'Credit card',
    },
    loan: {
        value: 'Loan',
        label: 'Loan',
    },
    medical: {
        value: 'Medical',
        label: 'Medical',
    },
    other: {
        value: 'Other',
        label: 'Other',
    },
};

export const QUIZ_TERMS_TYPES = {
    lowestMonthlyPayment: {
        value: 'Lowest monthly payment',
        label: 'Lowest monthly payment',
    },
    fastestPayoffTime: {
        value: 'Fastest payoff time',
        label: 'Fastest payoff time',
    },
    notSure: {
        value: 'Not sure',
        label: 'Not sure',
    },
};

export const QUIZ_ESTIMATED_CREDIT_SCORE_TYPES = {
    poor: {
        value: 'Poor (350-550)',
        label: 'Poor (350-550)',
    },
    fair: {
        value: 'Fair (550-630)',
        label: 'Fair (550-630)',
    },
    good: {
        value: 'Good (630-680)',

        label: 'Good (630-680)',
    },
    great: {
        value: 'Great (680-720+)',
        label: 'Great (680-720+)',
    },
};

export const QUIZ_FINANCING_AMOUNT_TYPES = {
    from1Kto10K: {
        value: '$1,000-$10,000',
        label: '$1,000-$10,000',
    },
    from10Kto25K: {
        value: '$10,000-$25,000',
        label: '$10,000-$25,000',
    },
    over25K: {
        value: 'Over $25,000',
        label: 'Over $25,000',
    },
};

export const QUIZ_GOAL_OPTIONS = [
    {
        ...QUIZ_GOAL_TYPES.improveCredit,
        name: 'goalType',
        checked: true,
    },
    {
        ...QUIZ_GOAL_TYPES.payMyDebt,
        name: 'goalType',
        checked: false,
    },
    {
        ...QUIZ_GOAL_TYPES.both,
        name: 'goalType',
        checked: false,
    },
];

export const QUIZ_DEBT_OPTIONS = [
    {
        ...QUIZ_DEBT_TYPES.creditCard,
        name: 'debtType',
        checked: true,
    },
    {
        ...QUIZ_DEBT_TYPES.loan,
        name: 'debtType',
        checked: false,
    },
    {
        ...QUIZ_DEBT_TYPES.medical,
        name: 'debtType',
        checked: false,
    },
    {
        ...QUIZ_DEBT_TYPES.other,
        name: 'debtType',
        checked: false,
    },
];

export const QUIZ_TERMS_OPTIONS = [
    {
        ...QUIZ_TERMS_TYPES.lowestMonthlyPayment,
        name: 'terms',
        checked: true,
    },
    {
        ...QUIZ_TERMS_TYPES.fastestPayoffTime,
        name: 'terms',
        checked: false,
    },
    {
        ...QUIZ_TERMS_TYPES.notSure,
        name: 'terms',
        checked: false,
    },
];

export const QUIZ_ESTIMATED_CREDIT_SCORE_OPTIONS = [
    {
        ...QUIZ_ESTIMATED_CREDIT_SCORE_TYPES.poor,
        name: 'credit',
        checked: true,
    },
    {
        ...QUIZ_ESTIMATED_CREDIT_SCORE_TYPES.fair,
        name: 'credit',
        checked: false,
    },
    {
        ...QUIZ_ESTIMATED_CREDIT_SCORE_TYPES.good,
        name: 'credit',
        checked: false,
    },
    {
        ...QUIZ_ESTIMATED_CREDIT_SCORE_TYPES.great,
        name: 'credit',
        checked: false,
    },
];

export const QUIZ_FINANCING_AMOUNT_OPTIONS = [
    {
        ...QUIZ_FINANCING_AMOUNT_TYPES.from1Kto10K,
        name: 'financing',
        checked: true,
    },
    {
        ...QUIZ_FINANCING_AMOUNT_TYPES.from10Kto25K,
        name: 'financing',
        checked: false,
    },
    {
        ...QUIZ_FINANCING_AMOUNT_TYPES.over25K,
        name: 'financing',
        checked: false,
    },
];

export const STEP_ONE_ITEMS = {
    goalType: QUIZ_GOAL_OPTIONS,
    debtType: QUIZ_DEBT_OPTIONS,
    termsType: QUIZ_TERMS_OPTIONS,
    estimatedCreditScore: QUIZ_ESTIMATED_CREDIT_SCORE_OPTIONS,
    financingAmount: QUIZ_FINANCING_AMOUNT_OPTIONS,
};
export const STEP_ONE_TITLES = [
    {
        step: 0,
        title: 'What are your',
        titleBold: 'goals?',
        subtitle: 'Complete a simple questionnaire to help us better understand the kind of financing you need',
        reverseTitle: true,
    },
    {
        step: 1,
        title: 'would you like to pay off?',
        titleBold: 'What type of debt',
        subtitle: 'Complete a simple questionnaire to help us better understand the kind of financing you need',
        reverseTitle: false,
    },
    {
        step: 2,
        title: 'What are',
        titleBold: 'your ideal terms?',
        subtitle: 'Complete a simple questionnaire to help us better understand the kind of financing you need',
        reverseTitle: true,
    },
    {
        step: 3,
        title: 'What is your',
        titleBold: 'estimated credit score?',
        subtitle: 'Complete a simple questionnaire to help us better understand the kind of financing you need',
        reverseTitle: true,
    },
    {
        step: 4,
        title: 'are you looking for?',
        titleBold: 'How much financing',
        subtitle: 'Complete a simple questionnaire to help us better understand the kind of financing you need',
        reverseTitle: false,
    },
    {
        step: 5,
        title: 'annual income?',
        titleBold: 'What is your ',
        subtitle: ' ',
        reverseTitle: false,
    },
];

export const QUIZ_DISCLAIMER_DEFAULT =
    'You understand by clicking on the “Start the questionnaire” button above, you agree that you are providing "written instructions" to Triton authorizing Triton to obtain your credit profile from any consumer reporting agency which can be used to evaluate your financial history for the purpose of providing you with targeted offers based on your credit profile and authorize Triton and its partners to contact you by phone, email or SMS text, which may include automated dialing, messaging and prerecorded voice technology. SMS text and data rates may apply. SMS text frequency varies. Text HELP for SMS text assistance or STOP to cancel SMS texts.';
