import s from './TripleSection.module.scss';

export const CARDS = [
    {
        image: './images/fill.svg',
        title: 'Complete a short questionnaire',
        desc: 'Our brief questionnaire analyzes your needs and instantly matches you with the best available options, without affecting your score.',
    },
    {
        image: './images/select.svg',
        title: (
            <>
                See your options and get&nbsp;your&nbsp;
                <strong className={s['triple-section-strong']}>free credit score</strong>
            </>
        ),
        desc: 'We analyze your questionnaire and match you with the best options that save you time and money.',
    },
    {
        image: './images/solution.svg',
        title: 'Select your ideal option',
        desc: 'Review your options then select the best plan for your credit or debt needs.',
    },
];
