import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useCloseModalByKeyboard } from '../../helpers/hooks/useCloseModalByKeyboard';
import classes from './Modal.module.scss';

const backdropVariants = {
    open: {
        opacity: 1,
        transition: { type: 'linear', duration: 0.2 },
    },
    close: {
        opacity: 0,
        transition: { type: 'linear', duration: 0.2 },
    },
};

const wrapVariants = {
    open: {
        translateY: 0,
        transition: { type: 'linear', duration: 0.2 },
    },
    close: {
        translateY: -20,
        transition: { type: 'linear', duration: 0.2 },
    },
};

const Modal = ({ opened, close, children, alwaysInTheDOM = false, noWrap = false, additionalClasses = '' }) => {
    const stopPropagationHandler = (e) => e.stopPropagation();

    useCloseModalByKeyboard(opened, close);

    const JSX = alwaysInTheDOM ? (
        <motion.div
            className={clsx(classes.backdrop, !opened && classes.disabled)}
            onClick={close}
            initial={false}
            animate={opened ? 'open' : 'close'}
            variants={backdropVariants}
        >
            <div className={classes.body}>
                <motion.div
                    className={clsx(noWrap ? null : classes.wrapNoBox, additionalClasses)}
                    onClick={stopPropagationHandler}
                    initial={{ translateY: 20 }}
                    variants={wrapVariants}
                >
                    {children}
                </motion.div>
            </div>
        </motion.div>
    ) : (
        <AnimatePresence>
            {opened && (
                <motion.div
                    className={classes.backdrop}
                    onClick={close}
                    initial={{ opacity: 0 }}
                    animate={backdropVariants.open}
                    exit={backdropVariants.close}
                >
                    <div className={classes.body}>
                        <motion.div
                            className={clsx(noWrap ? classes.wrapNoBox : classes.wrap, additionalClasses)}
                            onClick={stopPropagationHandler}
                            initial={{ translateY: 20 }}
                            animate={wrapVariants.open}
                            exit={wrapVariants.close}
                        >
                            {children}
                        </motion.div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );

    return ReactDOM.createPortal(JSX, document.body);
};

export default Modal;
