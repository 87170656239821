const DURATION = 0.65;
const DELAY = 0.3;

export const fadeInLeft = {
    hidden: {
        x: '-100%',
        opacity: 0,
    },
    visible: (custom) => ({
        x: 0,
        opacity: 1,
        transition: { delay: custom * DELAY, duration: DURATION },
    }),
};

export const fadeInRight = {
    hidden: {
        x: '100%',
        opacity: 0,
    },
    visible: (custom) => ({
        x: 0,
        opacity: 1,
        transition: { delay: custom * DELAY, duration: DURATION },
    }),
};

export const fadeInUp = {
    hidden: {
        y: '75%',
        opacity: 0,
    },
    visible: (custom) => ({
        y: 0,
        opacity: 1,
        transition: { delay: custom * DELAY, duration: DURATION },
    }),
};

export const fadeInDownHeader = {
    hidden: {
        y: '-100%',
        opacity: 0,
    },
    visible: (custom) => ({
        y: 0,
        opacity: 1,
        transition: { delay: custom * DELAY, duration: DURATION / 2 },
    }),
};
