import { memo } from 'react';
import clsx from 'clsx';
import classes from './ContentModal.module.scss';
import Modal from '../Modal/Modal';

const ContentModal = ({ opened, close, title, children }) => {
    return (
        <Modal opened={opened} close={close} additionalClasses={classes.wrap}>
            <header className={classes.header}>
                <h4 className={clsx(classes.title, 't-center')}>{title}</h4>
                <button className={classes.btn} onClick={close}>
                    <img src="/icons/icon_close.svg" alt="close" />
                </button>
            </header>
            {children}
        </Modal>
    );
};

export default memo(ContentModal);
