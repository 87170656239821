const IconDone = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
            <path
                fill="#98DB96"
                fillRule="evenodd"
                d="M5.333 24C5.333 13.707 13.707 5.333 24 5.333c10.293 0 18.667 8.374 18.667 18.667 0 10.293-8.374 18.667-18.667 18.667-10.293 0-18.667-8.374-18.667-18.667Zm18.017 6.155 10.11-10.11a1.554 1.554 0 0 0 0-2.2 1.553 1.553 0 0 0-2.198 0l-9.012 9.01-3.956-3.955a1.553 1.553 0 0 0-2.2 0 1.553 1.553 0 0 0 0 2.2l5.056 5.055a1.551 1.551 0 0 0 2.2 0Z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default IconDone;
