import { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { NavLink, Link } from 'react-router-dom';
import { Squeeze as Hamburger } from 'hamburger-react';
import { HeaderContext } from '../../App';
import { useMediaQuery } from '../../helpers/hooks/useMediaQuery';
import blackLogo from '../../assets/images/logo-black.svg';
import whiteLogo from '../../assets/images/logo-white.svg';
import Container from '../Container/Container';
import styles from './Header.module.scss';
import { HEADER_NAV } from './constants';
import { ROUTES, SOCIALS } from '../../constants/constants';
import { getViewportHeight } from '../../helpers/utils/utils';
import { useDocumentLockScrollY } from '../../helpers/hooks/useDocumentLockScrollY';
import Button from '../ui/Button/Button';

const Header = ({ themeDark, launchButtonText, launchButtonHref, isDropDownNav }) => {
    const { mobMenuIsOpen, setMobMenuIsOpen } = useContext(HeaderContext);
    const { lockScrollY, unlockScrollY } = useDocumentLockScrollY();
    const isMobile = useMediaQuery('(max-width: 1119px)');

    useEffect(() => {
        if (!isMobile) {
            setMobMenuIsOpen(false);
            window.removeEventListener('resize', getViewportHeight);
            unlockScrollY();
        } else {
            window.addEventListener('resize', getViewportHeight, false);
        }

        return () => {
            unlockScrollY();
            window.removeEventListener('resize', getViewportHeight);
        };
    }, [isMobile, setMobMenuIsOpen, unlockScrollY]);

    useEffect(() => {
        if (mobMenuIsOpen) {
            lockScrollY();
            getViewportHeight();
        } else {
            unlockScrollY();
        }
    }, [mobMenuIsOpen, lockScrollY, unlockScrollY]);

    const svgColor = themeDark ? styles.iconGray : styles.iconWhite;

    return (
        <header className={themeDark ? styles.black : styles.white}>
            <Container className={styles.container}>
                <div className={styles.pane}>
                    <div className={styles.logo}>
                        <Link to="/" onClick={() => mobMenuIsOpen && setMobMenuIsOpen(false)}>
                            {themeDark ? (
                                <img src={blackLogo} alt="Triton Finance" />
                            ) : (
                                <img src={whiteLogo} alt="Triton Finance" />
                            )}
                        </Link>
                    </div>
                    <div className={styles.hamburger}>
                        <Hamburger size={21} toggled={mobMenuIsOpen} toggle={setMobMenuIsOpen} />
                    </div>
                </div>
                <div
                    className={clsx(
                        styles.navWrap,
                        isDropDownNav && styles.dropDownNav,
                        mobMenuIsOpen && styles.isOpen,
                        'scrollbar-hidden'
                    )}
                >
                    <div
                        className={clsx(
                            styles.navHolder,
                            'nav-holder',
                            isDropDownNav && styles.dropDownNavHolder,
                            mobMenuIsOpen && styles.navOpen
                        )}
                    >
                        <nav className={styles.nav}>
                            {HEADER_NAV.map((item, i) => (
                                <NavLink
                                    key={i}
                                    to={item.to}
                                    className={({ isActive }) => clsx(styles.navLink, isActive && styles.active)}
                                >
                                    {item.name}
                                </NavLink>
                            ))}
                        </nav>
                        <div className={styles.links}>
                            <div className={`${styles.icons} ${svgColor}`}>
                                {SOCIALS.map((item, i) => {
                                    const { icon: Icon, href } = item;
                                    return (
                                        <a
                                            href={href}
                                            key={i}
                                            target="_blank"
                                            rel="noopener noreferrer nofollow noindex"
                                        >
                                            <Icon />
                                        </a>
                                    );
                                })}
                            </div>
                            <Button
                                href={launchButtonHref ? launchButtonHref : ROUTES.quiz}
                                variant={themeDark ? 'sixth' : 'third'}
                                className={clsx(styles.btn)}
                            >
                                {launchButtonText ? launchButtonText : 'Get Started'}
                            </Button>
                        </div>
                    </div>
                    <div className={styles.backdrop} />
                </div>
            </Container>
        </header>
    );
};

export default Header;
