import React from 'react';
import Rating from './components/Rating';
import styles from './SliderSection.module.scss';

const SliderCard = ({ text, name, shortName, date, customBg, customColor, rating }) => {
    return (
        <div className={styles.card}>
            <div className={styles['rating-wrap']}>
                <Rating className={styles.rating} rating={rating} />
            </div>
            <p className={styles.text}>{text}</p>
            <div className={styles.user}>
                <div className={styles.icon} style={{ background: customBg, color: customColor }}>
                    {shortName}
                </div>
                <div className={styles.nameCol}>
                    <p className={styles.name}>{name}</p>
                    <p className={styles.time}>{date}</p>
                </div>
            </div>
        </div>
    );
};

export default SliderCard;
