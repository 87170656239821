import React from 'react';
import Typography from '../../../Typography';
import Container from '../../../Container';

const ExpertiseInAction = () => {
    return (
        <Container>
            <Typography variant="heading-h2" asChild>
                <h2>
                    <strong>Expertise in action:</strong> elevating your credit potential
                </h2>
            </Typography>
        </Container>
    );
};

export default ExpertiseInAction;
