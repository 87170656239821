import capitalize from 'capitalize';

export const getViewportHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    return vh;
};

export const formattedDate = (dateString = '', comma = true) => {
    const date = new Date(dateString).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

    if (!comma) {
        return date.replace(/[,]/g, '');
    }

    return date;
};

export const getDefaultValues = (data, type = 'checked') => {
    if (type === 'checked') {
        return Object.keys(data).reduce((acc, key) => {
            const current = data[key].find((item) => item.checked);
            if (current) {
                const { name, value } = current;
                acc[name] = value;
            }

            return acc;
        }, {});
    }

    return;
};

export const updateQuizDefaultValues = (values, defaultValues) => {
    const data = {
        ...JSON.parse(JSON.stringify(defaultValues)),
        ...JSON.parse(JSON.stringify(values)),
    };
    sessionStorage.setItem('quiz', JSON.stringify(data));
    return data;
};

export const getQuizDefaultValues = (defaultValues) => {
    const data = JSON.parse(sessionStorage.getItem('quiz')) || {};
    if (Object.keys(data).length) {
        return {
            ...JSON.parse(JSON.stringify(defaultValues)),
            ...JSON.parse(JSON.stringify(data)),
        };
    }
    return defaultValues;
};

export const adeptOptionsStateUs = (data) => {
    return data.reduce((acc, item) => {
        acc.push({ label: capitalize.words(item), value: capitalize.words(item) });
        return acc;
    }, []);
};

export const addZeroToTen = (num) => {
    return num < 10 ? '0' + num : num;
};

export const getScheduleDays = (monthRage = 1) => {
    const MONTHS_RAGE = monthRage;
    const now = new Date();
    const month = now.getMonth() + 1;
    let neededYear = now.getFullYear();
    let neededMonth = month + MONTHS_RAGE;
    const date = now.getDate();

    if (neededMonth > 12) {
        const monthConf = neededMonth > 24 ? Math.floor(MONTHS_RAGE / 12) : 1;
        neededMonth = month + MONTHS_RAGE - 12 * monthConf;
        neededYear = now.getFullYear() + Math.floor((month + MONTHS_RAGE) / 12);
    }

    const neededDate = new Date(`${neededYear}-${addZeroToTen(neededMonth)}-${addZeroToTen(date)}`);

    const daysLag = Math.ceil(Math.abs(neededDate.getTime() - now.getTime()) / (1000 * 3600 * 24));

    const scheduleDays = new Array(daysLag).fill({}).map((_, i) => {
        if (i > 0) {
            return new Date(now.setDate(now.getDate() + 1));
        }
        return new Date(now.setDate(now.getDate()));
    });

    const formattedScheduleDays = scheduleDays.reduce((acc, date, i) => {
        if (date.getDay() === 0 || date.getDay() === 6) {
            return acc;
        }
        const dayNumeric = date.toLocaleDateString('en-US', {
            day: 'numeric',
        });
        const weekday = date.toLocaleDateString('en-US', {
            weekday: 'long',
        });
        const monthName = date.toLocaleDateString('en-US', {
            month: 'short',
        });
        const value = `${weekday} ${dayNumeric}, ${monthName}`;
        acc.push({
            label: value,
            id: i,
            value,
            date,
        });

        return acc;
    }, []);

    return formattedScheduleDays;
};

export const getScheduleTime = () => {
    return [
        {
            label: '8 AM',
            id: 1,
            value: '8 AM',
            hour: 8,
        },
        {
            label: '9 AM',
            id: 2,
            value: '9 AM',
            hour: 9,
        },
        {
            label: '10 AM',
            id: 3,
            value: '10 AM',
            hour: 10,
        },
        {
            label: '11 AM',
            id: 4,
            value: '11 AM',
            hour: 11,
        },
        {
            label: '12 PM',
            id: 5,
            value: '12 PM',
            hour: 12,
        },
        {
            label: '1 PM',
            id: 6,
            value: '1 PM',
            hour: 13,
        },
        {
            label: '2 PM',
            id: 7,
            value: '2 PM',
            hour: 14,
        },
        {
            label: '3 PM',
            id: 8,
            value: '3 PM',
            hour: 15,
        },
        {
            label: '4 PM',
            id: 9,
            value: '4 PM',
            hour: 16,
        },
        {
            label: '5 PM',
            id: 10,
            value: '5 PM',
            hour: 17,
        },
        {
            label: '6 PM',
            id: 11,
            value: '6 PM',
            hour: 18,
        },
        {
            label: '7 PM',
            id: 12,
            value: '7 PM',
            hour: 19,
        },
        {
            label: '8 PM',
            id: 13,
            value: '8 PM',
            hour: 20,
        },
    ];
};

export const intlNumberFormat = (value) => {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
    }).format(value);
};

export const intlPluralRulesSimplify = (value, singular, plural) => {
    const pluralRules = new Intl.PluralRules('en-US');
    const rule = pluralRules.select(value);

    switch (rule) {
        case 'one':
            return singular;
        default:
            return plural;
    }
};
