import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { HeaderContext } from '../../App';
import clsx from 'clsx';
import WithHeader from '../../HOC/WithHeader/WithHeader';
import styles from './AgreementsPage.module.scss';
import Policy from './Policy';
import Terms from './Terms';
import Container from '../../components/Container/Container';
import { fadeInDownHeader } from '../../helpers/utils/animations';
import { motion } from 'framer-motion';

const AgreementsPage = () => {
    const location = useLocation().pathname.slice(1);
    const headerRef = useRef(null);
    const { mobMenuIsOpen } = useContext(HeaderContext);

    useEffect(() => {
        let scrollTopOldValue = 0;

        const handlerScroll = () => {
            if (!headerRef.current) {
                return;
            }

            if (window.pageYOffset <= scrollTopOldValue) {
                headerRef.current.classList.remove('hide-header');
            } else {
                headerRef.current.classList.add('hide-header');
            }
            scrollTopOldValue = window.pageYOffset;
        };
        if (headerRef.current) {
            window.addEventListener('scroll', handlerScroll, false);
        } else {
            window.removeEventListener('scroll', handlerScroll);
        }

        return () => {
            window.removeEventListener('scroll', handlerScroll);
        };
    }, [headerRef]);

    return (
        <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} className={styles.main}>
            <motion.div variants={fadeInDownHeader} className={styles.header} ref={headerRef}>
                <WithHeader isDropDownNav></WithHeader>
            </motion.div>
            <div className={clsx(styles.backdrop, mobMenuIsOpen && styles.show)} />

            <Container>
                {location === 'policy' && <Policy />}
                {location === 'terms' && <Terms />}
            </Container>
        </motion.div>
    );
};

export default AgreementsPage;
