import { useEffect, memo, useState, forwardRef, useRef } from 'react';
import ReactSelect, { components } from 'react-select';
import clsx from 'clsx';
import Error from '../Error';
import Label from '../Label/Label';
import classes from './Select.module.scss';
import DropdownIndicator from './DropdownIndicator';
import IconArrowDown from '../Icons/IconArrowDown';
import Option from './Option';

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete={'off'} />;

const Select = forwardRef(
    (
        {
            options,
            label,
            required,
            id,
            error,
            helperText,
            className,
            placeholder,
            isClearValue,
            components,
            onChange,
            closeMenuOnSelect,
            isDisabled,
            ...rest
        },
        ref
    ) => {
        const [selectedOption, setSelectedOption] = useState();
        const selectRef = useRef(null);

        useEffect(() => {
            if (!isClearValue) {
                return;
            }

            selectRef.current?.setValue([], 'clear');
        }, [isClearValue, selectRef]);

        const selectChangeHandler = (data) => {
            if (data.value === selectedOption?.value) {
                return;
            }

            setSelectedOption(data);

            if (typeof onChange === 'function') {
                onChange(data.value);
            }
        };

        return (
            <div className={clsx(required && classes.required)}>
                {label && <Label htmlFor={id}>{label}</Label>}
                <ReactSelect
                    className={clsx(classes.holder, error && classes.error, className)}
                    classNamePrefix="react-select"
                    components={{
                        DropdownIndicator: (props) => {
                            return (
                                <DropdownIndicator {...props}>
                                    <IconArrowDown />
                                </DropdownIndicator>
                            );
                        },
                        Option,
                        Input,
                        ...components,
                    }}
                    id={id}
                    instanceId={id}
                    inputId={id}
                    onChange={selectChangeHandler}
                    options={options}
                    closeMenuOnSelect={closeMenuOnSelect}
                    placeholder={placeholder}
                    menuPosition="fixed"
                    menuPortalTarget={document.body}
                    ref={(r) => {
                        ref = r;
                        selectRef.current = r;
                    }}
                    noOptionsMessage={() => 'Not found'}
                    isDisabled={isDisabled}
                    autoComplete="off"
                    {...rest}
                />
                {error && <Error>{helperText}</Error>}
            </div>
        );
    }
);

Select.displayName = 'Select';

export default memo(Select);
