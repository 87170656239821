import { motion } from 'framer-motion';
import clsx from 'clsx';
import { fadeInLeft, fadeInRight } from '../../../../helpers/utils/animations';
import LinkButton from '../../../LinkButton/LinkButton';
import Container from '../../../Container/Container';
import ButtonFollow from '../../../ui/ButtonFollow/ButtonFollow';
import s from './DoubleSection.module.scss';

const DoubleSection = ({
    image,
    imageMob,
    titleBold,
    titleRest,
    isReversed = false,
    title,
    text,
    follow,
    imagePosition,
    variant,
    noHelperText,
    linkTo,
}) => {
    const position = imagePosition === 'right' ? s.positionRight : '';

    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(s.root, s[variant], 'o-hidden')}
        >
            <Container>
                <div className={clsx(s.holder, position)}>
                    <motion.picture
                        className={s.image}
                        custom={2}
                        variants={imagePosition === 'right' ? fadeInRight : fadeInLeft}
                    >
                        <source srcSet={imageMob} media="(max-width: 479px)" />
                        <img src={image} alt={title} />
                    </motion.picture>
                    <div className={s.wrap}>
                        <div className={s.content}>
                            <div className={s.inner}>
                                <motion.h2
                                    className={s.title}
                                    custom={1}
                                    variants={imagePosition === 'right' ? fadeInLeft : fadeInRight}
                                >
                                    {!isReversed ? (
                                        <>
                                            {titleRest} <strong>{titleBold}</strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong>{titleBold}</strong> {titleRest}
                                        </>
                                    )}
                                </motion.h2>
                                <motion.p
                                    custom={2}
                                    variants={imagePosition === 'right' ? fadeInLeft : fadeInRight}
                                    className={clsx(
                                        s.subtitle,
                                        !['button', 'link'].includes(follow) && s.noBottomOffset
                                    )}
                                >
                                    {text}
                                </motion.p>
                                <motion.div custom={3} variants={imagePosition === 'right' ? fadeInLeft : fadeInRight}>
                                    {follow === 'button' && (
                                        <LinkButton
                                            hasIcon
                                            linkTo={linkTo}
                                            text="Get Free Consultation"
                                            noHelperText={noHelperText}
                                        />
                                    )}
                                    {follow === 'link' && (
                                        <ButtonFollow href="/help" className={s.link}>
                                            Go to how we hel
                                        </ButtonFollow>
                                    )}
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </motion.section>
    );
};

export default DoubleSection;
