export const MARKS_CHECKED = [
    {
        text: 'Credit Cards',
    },
    {
        text: 'Collections',
    },
    {
        text: 'Personal Loans',
    },
    {
        text: 'Business Loans',
    },
    {
        text: 'Medical Bills',
    },
    {
        text: 'Lines of Credit',
    },
];

export const MARKS_NO_CHECKED = [
    {
        text: 'Home Loans',
    },
    {
        text: 'Auto Loans',
    },
    {
        text: 'Government Loans',
    },
    {
        text: 'Taxes',
    },
    {
        text: 'Student Loans',
    },
    {
        text: 'Equity Loans',
    },
];
