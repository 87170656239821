import clsx from 'clsx';
import React from 'react';
import s from './ImageBlock.module.scss';
import Container from '../Container/Container';
import ButtonFollow from '../ui/ButtonFollow/ButtonFollow';
import { formattedDate } from '../../helpers/utils/utils';
import { fadeInLeft, fadeInUp } from '../../helpers/utils/animations';
import { motion } from 'framer-motion';

const ImageBlock = ({ className, title, published, image, id, customHref, imageClassName }) => {
    return (
        <article>
            <header className={s.header}>
                <h2 className={s.title}>
                    Triton will help you <strong>change your life for the better</strong>
                </h2>
                <p className={s.subtitle}>
                    Watch our client's success story, who used our services to solve his loan problems
                </p>
            </header>
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                className={clsx(s.wrap, className, 'o-hidden')}
            >
                <motion.div variants={fadeInUp} className={s.image}>
                    <img className={imageClassName} src={image} alt={title} />
                </motion.div>
                <Container className={s.holder}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} className={s.content}>
                        <motion.h1 custom={1} variants={fadeInLeft}>
                            <strong>{title}</strong>
                        </motion.h1>
                        <motion.div custom={2} variants={fadeInLeft} className={s.date}>
                            Published {formattedDate(published)}
                        </motion.div>
                        <motion.div custom={3} variants={fadeInLeft} className={s.action}>
                            <ButtonFollow isDefaultLink={!!customHref} href={customHref ?? `/blog/${id}`}>
                                Read in our blog
                            </ButtonFollow>
                        </motion.div>
                    </motion.div>
                </Container>
            </motion.div>
        </article>
    );
};

export default ImageBlock;
