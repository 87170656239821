import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Article.module.scss';

const Article = ({ title, subtitle, section, published_at, image_url, id }) => {
    return (
        <div className={styles.card}>
            <Link to={`/blog/${id}`}>
                <div className={styles.imageBlock}>
                    <img src={image_url} alt={section} />
                    <p>Section: {section}</p>
                </div>
                <div className={styles.textBlock}>
                    <p className={styles.date}>{new Date(published_at).toLocaleDateString()}</p>
                    <h4>{title}</h4>
                    <p className={styles.description}>{subtitle}</p>
                </div>
            </Link>
        </div>
    );
};

export default Article;
