import { memo } from 'react';
import { Slot } from '@radix-ui/react-slot';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import s from './Typography.module.css';

const Typography = ({ className, children, variant, asChild, ...rest }) => {
    const Component = asChild ? Slot : 'div';

    return (
        <Component className={clsx(s.wrap, s[variant], className)} {...rest}>
            {children}
        </Component>
    );
};

Typography.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf([
        'heading-h1',
        'heading-h1-md',
        'heading-h1-sm',
        'heading-h2',
        'heading-h3',
        'heading-h4',
        'button-text',
        'text-md',
        'text-small',
        'text-section',
        'text-section-sm',
        'text-icon',
        'helper-text',
        'descriptors',
        'numbers',
        'numbers-small',
        'numbers-xs',
    ]).isRequired,
    asChild: PropTypes.bool,
};

export default memo(Typography);
