import { useRef } from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Virtual } from 'swiper';
import SliderCard from './SliderCard';
import { useLocation } from 'react-router-dom';
import Article from '../Article/Article';
import Container from '../Container/Container';
import styles from './SliderSection.module.scss';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import NavigationButton from '../ui/NavigationButton/NavigationButton';
import { fadeInLeft, fadeInUp } from '../../helpers/utils/animations';
import { motion } from 'framer-motion';

const SliderSection = ({ titleRest, titleBold, subtitle, sliderData }) => {
    const location = useLocation().pathname.slice(1);
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(styles.slider, 'o-hidden')}
        >
            <Container>
                <div className={styles.navWrap}>
                    <motion.h2 custom={1} variants={fadeInLeft}>
                        {titleRest} <strong>{titleBold}</strong>
                    </motion.h2>
                    <div className={styles.navigation}>
                        <NavigationButton ref={navigationPrevRef} watchOverflow />
                        <NavigationButton ref={navigationNextRef} watchOverflow reverse />
                    </div>
                </div>
                {subtitle && (
                    <motion.p custom={2} variants={fadeInLeft} className={styles.subtitle}>
                        {subtitle}
                    </motion.p>
                )}
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
                    <motion.div variants={fadeInUp}>
                        <Swiper
                            slidesPerView={1}
                            loop={true}
                            loopFillGroupWithBlank={true}
                            modules={[Pagination, Navigation, Virtual]}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                            onUpdate={(swiper) => {
                                swiper.params.navigation.prevEl = navigationPrevRef.current;
                                swiper.params.navigation.nextEl = navigationNextRef.current;
                                swiper.navigation.destroy();
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }}
                            className={styles.content}
                            breakpoints={{
                                600: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                900: {
                                    slidesPerView: 3,
                                    spaceBetween: 80,
                                },
                            }}
                        >
                            {sliderData.map((card, index) => (
                                <SwiperSlide key={card.id} virtualIndex={index} style={{ top: '20%' }}>
                                    {location.split('/')[0] === 'blog' ? (
                                        <Article {...card} />
                                    ) : (
                                        <SliderCard {...card} />
                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </motion.div>
                </motion.div>
            </Container>
        </motion.section>
    );
};

export default SliderSection;
