import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '../../../../../Typography';
import s from './QuizResultTitle.module.css';

const QuizResultTitle = ({ className, title, subtitle, description }) => {
    return (
        <div className={clsx(s.wrap, className)}>
            <div className={s['image-wrap']}>
                <img className={s.image} src="/images/svg/logo-icon.svg" alt="" width="80" height="100" />
            </div>
            <div className={s['title-wrap']}>
                <Typography className={s.title} variant="heading-h2" asChild>
                    <h1>
                        <strong>{title}</strong>
                    </h1>
                </Typography>
                <Typography className={s.subtitle} variant="heading-h2" asChild>
                    <h2>{subtitle}</h2>
                </Typography>
                <Typography className={s.description} variant="helper-text" asChild>
                    <h5>{description}</h5>
                </Typography>
            </div>
        </div>
    );
};

QuizResultTitle.propTypes = {
    className: PropTypes.string,
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
};

export default memo(QuizResultTitle);
