import { memo } from 'react';
import { motion } from 'framer-motion';
import { fadeInLeft, fadeInRight } from '../../helpers/utils/animations';
import StatisticItem from './components/StatisticItem';
import Container from '../Container';
import Typography from '../Typography';
import ButtonFollow from '../ui/ButtonFollow';
import s from './Partnership.module.css';

const Partnership = () => {
    return (
        <motion.section variants={fadeInLeft}>
            <Container className={s.wrap} size="lg">
                <motion.div
                    className={s['stats-wrap']}
                    variants={fadeInLeft}
                    transition={{
                        duration: 0.3,
                        delay: 0.1,
                        ease: 'easeInOut',
                    }}
                >
                    <h2 className={s.title}>
                        We&apos;ve been a <strong>leader in credit solutions</strong> for years
                    </h2>
                    <div className={s.stats}>
                        <StatisticItem
                            title={
                                <span>
                                    500<strong>+</strong>
                                </span>
                            }
                            description={<span>Confirmed deletions</span>}
                        />
                        <StatisticItem
                            title={
                                <span>
                                    300<strong>+</strong>
                                </span>
                            }
                            description={<span>Satisfied customers</span>}
                        />
                        <StatisticItem
                            title={
                                <span>
                                    15<strong>+</strong>
                                </span>
                            }
                            description={<span>Years of experience</span>}
                        />
                        <StatisticItem image={'bbb-accredited'} description={<span>BBB accredited</span>} />
                    </div>
                </motion.div>
                <motion.div
                    className={s.divider}
                    variants={fadeInLeft}
                    transition={{
                        duration: 0.3,
                        delay: 0.3,
                        ease: 'easeInOut',
                    }}
                />
                <motion.div
                    className={s['video-wrap']}
                    variants={fadeInRight}
                    transition={{
                        duration: 0.3,
                        delay: 0.5,
                        ease: 'easeInOut',
                    }}
                >
                    <Typography className={s.title} variant="title-h4" asChild>
                        <h2>
                            See how better credit can <strong>change your life for the better</strong>
                        </h2>
                    </Typography>
                    <Typography className={s.subtitle} variant="heading-h5" asChild>
                        <div>
                            Watch a real client's success story after Triton helped them get the financing he needed to
                            overcome his debt
                        </div>
                    </Typography>
                    <motion.div custom={3} variants={fadeInRight}>
                        <ButtonFollow className={s.cta} variant="play" reverse>
                            Play Video
                        </ButtonFollow>
                    </motion.div>
                </motion.div>
            </Container>
        </motion.section>
    );
};

export default memo(Partnership);
