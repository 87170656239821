import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useLocalStorage } from './useLocalStorage';
import { getMarketingId, getAffiliateId } from '../components/Quiz/utils/common';
import { COOKIE_MARKETING_ID, AFFILIATE_MARKETING_ID_PREFIX, COOKIE_AFFILIATE_ID } from '../constants/constants';

export const useMarketingIdStorage = () => {
    const [searchParams] = useSearchParams();

    const marketingIdFromSearchParams = searchParams.get('subid') ?? '';
    const affiliateIdFromSearchParams = searchParams.get(AFFILIATE_MARKETING_ID_PREFIX) ?? '';

    const [marketingIdFromLocalStorage, setMarketingIdFromLocalStorage] = useLocalStorage(COOKIE_MARKETING_ID, '');
    const [affiliateIdFromLocalStorage, setAffiliateIdFromLocalStorage] = useLocalStorage(COOKIE_AFFILIATE_ID, '');

    const getMarketingIdFromEverywhere = useCallback(() => {
        if (marketingIdFromSearchParams) {
            return marketingIdFromSearchParams;
        }

        return getMarketingId();
    }, [marketingIdFromSearchParams]);

    const getAffiliateIdFromEverywhere = useCallback(() => {
        if (affiliateIdFromSearchParams) {
            return affiliateIdFromSearchParams;
        }

        return getAffiliateId();
    }, [affiliateIdFromSearchParams]);

    const marketingIdFromCookie = useMemo(() => {
        if (marketingIdFromSearchParams) {
            return marketingIdFromSearchParams;
        }

        if (marketingIdFromLocalStorage) {
            return marketingIdFromLocalStorage;
        }

        return getMarketingIdFromEverywhere();
    }, [getMarketingIdFromEverywhere, marketingIdFromLocalStorage, marketingIdFromSearchParams]);

    const affiliateIdFromCookie = useMemo(() => {
        if (affiliateIdFromSearchParams) {
            return affiliateIdFromSearchParams;
        }

        if (affiliateIdFromLocalStorage) {
            return affiliateIdFromLocalStorage;
        }

        return getAffiliateIdFromEverywhere();
    }, [affiliateIdFromLocalStorage, affiliateIdFromSearchParams, getAffiliateIdFromEverywhere]);

    useEffect(() => {
        if (marketingIdFromSearchParams) {
            setMarketingIdFromLocalStorage(marketingIdFromSearchParams);
            Cookies.set(COOKIE_MARKETING_ID, marketingIdFromSearchParams, {
                expires: 30,
            });
        }
    }, [marketingIdFromSearchParams, setMarketingIdFromLocalStorage]);

    useEffect(() => {
        if (affiliateIdFromSearchParams) {
            setAffiliateIdFromLocalStorage(affiliateIdFromSearchParams);
            Cookies.set(COOKIE_AFFILIATE_ID, affiliateIdFromSearchParams, {
                expires: 30,
            });
        }
    }, [affiliateIdFromSearchParams, setAffiliateIdFromLocalStorage]);

    return {
        marketingId: marketingIdFromCookie,
        affiliateId: affiliateIdFromCookie,
        getMarketingId: getMarketingIdFromEverywhere,
        getAffiliateId: getAffiliateIdFromEverywhere,
    };
};
