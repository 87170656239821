import clsx from 'clsx';
import LinkButton from '../LinkButton/LinkButton';
import styles from './DoubleSection.module.scss';
import Container from '../Container/Container';
import ButtonFollow from '../ui/ButtonFollow/ButtonFollow';
import { fadeInLeft, fadeInRight } from '../../helpers/utils/animations';
import { motion } from 'framer-motion';

const DoubleSection = ({
    image,
    imageMob,
    titleBold,
    titleRest,
    isReversed = false,
    title,
    text,
    follow,
    imagePosition,
    variant,
    noHelperText,
    innerClassName,
    subtitleClassName,
}) => {
    const position = imagePosition === 'right' ? styles.positionRight : '';

    return (
        <motion.article
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(styles.root, styles[variant], 'o-hidden')}
        >
            <Container>
                <div className={clsx(styles.holder, position)}>
                    <motion.picture
                        className={styles.image}
                        custom={2}
                        variants={imagePosition === 'right' ? fadeInRight : fadeInLeft}
                    >
                        <source srcSet={imageMob} media="(max-width: 479px)" />
                        <img src={image} alt={title} />
                    </motion.picture>
                    <div className={styles.wrap}>
                        <div className={styles.content}>
                            <motion.h2 custom={1} variants={imagePosition === 'right' ? fadeInLeft : fadeInRight}>
                                {!isReversed ? (
                                    <>
                                        {titleRest} <strong>{titleBold}</strong>
                                    </>
                                ) : (
                                    <>
                                        <strong>{titleBold}</strong> {titleRest}
                                    </>
                                )}
                            </motion.h2>
                            <div className={clsx(styles.inner, innerClassName)}>
                                <motion.p
                                    custom={2}
                                    variants={imagePosition === 'right' ? fadeInLeft : fadeInRight}
                                    className={clsx(
                                        styles.subtitle,
                                        subtitleClassName,
                                        !['button', 'link'].includes(follow) && styles.noBottomOffset
                                    )}
                                >
                                    {text}
                                </motion.p>
                                <motion.div custom={3} variants={imagePosition === 'right' ? fadeInLeft : fadeInRight}>
                                    {follow === 'button' && <LinkButton hasIcon noHelperText={noHelperText} />}
                                    {follow === 'link' && (
                                        <ButtonFollow href="/help" className={styles.link}>
                                            Go to how we help
                                        </ButtonFollow>
                                    )}
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </motion.article>
    );
};

export default DoubleSection;
