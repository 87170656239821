import { useMemo } from 'react';

export const useGetCreditScoreProgress = () => {
    const result = useMemo(() => {
        return [
            {
                name: '1st month',
                value: 580,
            },
            {
                name: '2nd month',
                value: 610,
            },
            {
                name: '3rd month',
                value: 635,
            },
            {
                name: '4th month',
                value: 675,
            },
            {
                name: '5th month',
                value: 680,
            },
            {
                name: '6th month',
                value: 700,
            },
            {
                name: '7th month',
                value: 730,
            },
        ];
    }, []);

    return result;
};
