import { useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './Timeline.module.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { companyHistory } from '../../config/companyHistory';

const Timeline = () => {
    const [activeTooltip, setActiveTooltip] = useState(companyHistory[0]?.id);

    const helper = (id) => {
        setActiveTooltip(id);
    };

    const checkerActiveTooltip = (id) => {
        return id === activeTooltip;
    };

    const getPosition = (index) => {
        if (companyHistory.length) {
            return (100 / (companyHistory.length + 1)) * index;
        }
    };

    useEffect(() => {
        const resizeHandler = () => {
            ReactTooltip.rebuild();
            requestAnimationFrame(() => {
                ReactTooltip.rebuild();
            });
        };

        window.removeEventListener('resize', resizeHandler);

        window.addEventListener('resize', resizeHandler, false);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    return (
        <>
            <div className={styles.content}>
                <svg xmlns="http://www.w3.org/2000/svg" className={styles.startPoint} viewBox="0 0 10 11">
                    <circle cx="5" cy="5.97" r="5" />
                </svg>
                <div className={styles.timeline} />
                <svg xmlns="http://www.w3.org/2000/svg" className={styles.arrow} viewBox="0 0 10 16">
                    <path
                        fillRule="evenodd"
                        d="M.616.42a1.25 1.25 0 0 0 0 1.768L6.4 7.97.616 13.753a1.25 1.25 0 1 0 1.768 1.768L9.05 8.854a1.25 1.25 0 0 0 0-1.768L2.384.42a1.25 1.25 0 0 0-1.768 0Z"
                        clipRule="evenodd"
                    />
                </svg>
                {companyHistory.map((history, i) => (
                    <div
                        className={clsx(styles.ellipseData, checkerActiveTooltip(history.id) && styles.active)}
                        key={history.id}
                    >
                        <div
                            className={styles.year}
                            style={{
                                left: `${getPosition(i + 1)}%`,
                            }}
                        >
                            <svg
                                data-tooltip-id="tooltip-history"
                                data-tooltip-content={history.description}
                                // onMouseEnter={() => helperTextHandler(history.id)}
                                // onMouseLeave={() => helperTextHandler(null)}
                                onClick={() => helper(history.id)}
                                xmlns="http://www.w3.org/2000/svg"
                                className={styles.point}
                                viewBox="0 0 16 17"
                            >
                                <circle cx="8" cy="8.97" r="6" />
                            </svg>
                            {checkerActiveTooltip(history.id) ? (
                                <p
                                    style={{ opacity: 1 }}
                                    className={`${checkerActiveTooltip(history.id) ? styles.textBold : ''} `}
                                >
                                    {history.year}
                                </p>
                            ) : (
                                <p className={checkerActiveTooltip(history.id) ? styles.textBold : ''}>
                                    <span>{history.year}</span>
                                </p>
                            )}
                        </div>
                    </div>
                ))}
                <ReactTooltip
                    id="tooltip-history"
                    place="top"
                    effect="solid"
                    className={styles.tooltip}
                    classNameArrow={styles.tooltipArrow}
                    arrowColor="#F9FAFB"
                    borderColor="#e1e1e1"
                    border={true}
                    openOnClick={true}
                    isOpen={true}
                    delayShow={200}
                    delayHide={200}
                />
            </div>
        </>
    );
};

export default Timeline;
