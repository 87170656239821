import React from 'react';
import clsx from 'clsx';
import styles from './MarkBlock.module.scss';
import IconDone from '../ui/Icons/IconDone';
import IconNone from '../ui/Icons/IconNone';

const MarkBlock = ({ text, checked }) => {
    return (
        <div className={styles.mark}>
            {checked ? <IconDone className={styles.doneImage} /> : <IconNone className={styles.nopeImage} />}
            <p className={clsx(checked ? `${styles.regularText}` : `${styles.helperText}`)}>{text}</p>
        </div>
    );
};

export default MarkBlock;
