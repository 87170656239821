export const companyHistory = [
    {
        id: 'h1',
        year: '2018',
        description: 'Triton Finance was founded with the mission to help consumers overcome debt.',
    },
    {
        id: 'h2',
        year: '2020',
        description:
            'The Triton team matched consumers with over $2,000,000 in financing.',
    },
    {
        id: 'h3',
        year: '2022',
        description: 'Expanded our network to over 300 lenders nationwide.',
    },
];
