import { memo } from 'react';
import clsx from 'clsx';
import s from './QuizStepperProgress.module.scss';

/**
 *
 * @param {{
 *  stepIndex: number
 *  activeStepIndex: number;
 * }} props
 */
const QuizStepperProgress = ({ stepIndex, activeStepIndex }) => {
    return (
        <div className={s.wrap}>
            <div
                className={clsx(s.item, {
                    [s.active]: activeStepIndex >= stepIndex,
                })}
            />
        </div>
    );
};

export default memo(QuizStepperProgress);
