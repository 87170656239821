import { memo } from 'react';
import clsx from 'clsx';
import Icon from '../../../ui/Icon';
import s from './Rating.module.css';

const Rating = ({ className, rating }) => {
    return (
        <div className={clsx(s.wrap, className)}>
            {Array.from({ length: 5 }, (_, index) => {
                const isFilled = index + 1 <= rating;

                return (
                    <div key={index} className={clsx(s.star, { [s.filled]: isFilled })}>
                        <Icon className={s.icon} id="icon-star_24" />
                    </div>
                );
            })}
        </div>
    );
};

export default memo(Rating);
