export const RESULTS = [
    {
        num: '300',
        plus: true,
        text: 'Satisfied credit repair customers',
    },
    {
        num: '$100M',
        plus: true,
        text: 'Consumer debt resolved',
    },
    {
        num: '4.8',
        star: true,
        text: 'Excellent client rating',
    },
];
