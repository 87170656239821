import clsx from 'clsx';
import Container from '../Container/Container';
import Accordion from '../ui/Accordion/Accordion';
import { fadeInUp } from '../../helpers/utils/animations';
import { motion } from 'framer-motion';
import styles from './Faq.module.scss';

const Faq = ({ questions, title }) => {
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(styles.section, 'o-hidden')}
        >
            <Container>
                <motion.h2 custom={1} variants={fadeInUp}>
                    {title}
                </motion.h2>
                <motion.p custom={2} variants={fadeInUp} className={styles.subtitle}>
                    Here you can find answers to questions you didn't find when exploring the site. If you still have
                    questions,{' '}
                    <a className={styles.link} href="https://www.google.com">
                        contact us
                    </a>
                </motion.p>
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
                    <motion.div variants={fadeInUp} className={styles.content}>
                        <Accordion list={questions} />
                    </motion.div>
                </motion.div>
            </Container>
        </motion.section>
    );
};

export default Faq;
