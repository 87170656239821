import { components } from 'react-select';
import classes from './Select.module.scss';
import IconOptionSelected from '../Icons/IconOptionSelected';

const Option = (props) => {
    const { data } = props;
    return (
        <components.Option {...props}>
            <div className={classes.option}>
                <span className="flex-auto">{data.label}</span>
                <IconOptionSelected />
            </div>
        </components.Option>
    );
};

export default Option;
