import { useContext, useEffect, useRef } from 'react';
import { actionsSelector, useQuizStore } from '../../components/Quiz/store';
import { ROUTES } from '../../constants/constants';
import SliderSection from '../../components/SliderSection';
import DoubleSection from '../../components/DoubleSection/DoubleSection';
import TopContentBlock from '../../components/TopContentBlock/TopContentBlock';
import CalculatorSection from '../../components/CalculatorSection/CalculatorSection';
import ArticleSection from '../../components/Article/ArticleSection';
import s from './HomePage.module.scss';
import ImageBlock from '../../components/ImageBlock/ImageBlock';
import { AppContext } from '../../App';
import Presentation from './components/Presentation';
import { DEBT_CONSOLIDATOR_DOUBLE_SECTION_CONTENT, HOME_DOUBLE_SECTIONS_ITEMS, HOME_PAGE_REVIEWS } from './constants';
import DebtTypes from '../../components/DebtTypes/DebtTypes';
import TripleSection from '../../components/TripleSection/TripleSection';
import CreditRestorationProgressChart from '../../components/CreditScoreSolutions/components/CreditRestoration/components/CreditRestorationProgressChart';
import Button from '../../components/ui/Button';
import { Link } from 'react-router-dom';

const HomePage = () => {
    const { articles, testimonials } = useContext(AppContext);
    const quizActions = useQuizStore(actionsSelector);

    const videoRef = useRef(null);

    const executeScroll = () => videoRef.current.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        quizActions.cleanUp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TopContentBlock
                titleBold="Helping Americans"
                titleRest="achieve financial stability"
                subtitle={
                    <>
                        Whether you need to <strong>consolidate debt</strong> or <strong>repair your credit</strong>,
                        Triton is here to help you every step of the way.
                    </>
                }
                image="./images/home-main.svg"
                imageMob="./images/home-mainMob.svg"
                blueBlock
                button
                followUs
                noHelperText={false}
            />
            <Presentation
                title={
                    <>
                        Triton has been a <strong> leader in debt and credit solutions</strong> for years
                    </>
                }
                executeScroll={executeScroll}
            />
            <SliderSection
                titleBold="for you to succeed"
                titleRest="We show you the best options"
                subtitle="Don't take our word for it, see what our clients have to say"
                sliderData={HOME_PAGE_REVIEWS}
            />
            <section className={s.doubleSections}>
                <h2 className={s.sectionTitle}>
                    Our <strong>services</strong>
                </h2>
                {HOME_DOUBLE_SECTIONS_ITEMS.map((props, i) => (
                    <DoubleSection key={i} {...props} />
                ))}
            </section>
            {/* <section>
                <CreditRestorationProgressChart />
                <div className={s['cta-centered']}>
                    <Button href="/quiz" size="md" hasIcon>
                        Get started
                    </Button>
                </div>
            </section> */}
            {/* <section className={s.section}>
                <DoubleSection
                    {...DEBT_CONSOLIDATOR_DOUBLE_SECTION_CONTENT}
                    innerClassName={s['inner-full-width']}
                    subtitleClassName={s['subtitle-full-width']}
                />
            </section> */}
            <DebtTypes
                title={
                    <>
                        What <strong>debts</strong> can we <strong>help you</strong> with?
                    </>
                }
            />
            <TripleSection
                title={
                    <>
                        How to <strong>get started?</strong>
                    </>
                }
                subtitle="See how three simple steps can transform your life financially."
            />
            <section ref={videoRef}>
                <ImageBlock
                    className={s.imageBlock}
                    id={testimonials[0]?.id}
                    title={testimonials[0]?.title}
                    published={testimonials[0]?.published_at}
                    image={testimonials[0]?.image_url}
                />
            </section>
            {/* <Faq questions={dataAccordion} /> */}
            <ArticleSection
                titleBold="Check interesting articles"
                titleRest="about financial solutions on our blog"
                subtitle="We also have useful materials on financial literacy and communication with creditors"
                numberOfArticles={3}
                linkTo={ROUTES.blog}
                linkName="Go to our blog"
                articles={articles}
            />
            <CalculatorSection />
        </>
    );
};

export default HomePage;
