import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import LoadingBlock from '../components/LoadingBlock/LoadingBlock';
import { useContext } from 'react';
import { HeaderContext } from '../App';
import MessageModal from '../components/MessageModal/MessageModal';

const MainLayout = () => {
    const { loaderBlock, messageModalOpen, setMessageModalOpen, messageModalText } = useContext(HeaderContext);

    return (
        <>
            <main>
                <Outlet />
            </main>
            <Footer />
            <LoadingBlock opened={loaderBlock} />
            <MessageModal
                opened={messageModalOpen}
                close={() => {
                    setMessageModalOpen(false);
                }}
                message={messageModalText}
            />
        </>
    );
};

export default MainLayout;
