import { forwardRef, memo } from 'react';
import clsx from 'clsx';
import styles from './Input.module.scss';
import Error from '../Error';
import Label from '../Label/Label';

const Input = forwardRef(
    (
        {
            id,
            label,
            type = 'text',
            name,
            value,
            placeholder,
            error,
            helperText = '',
            variant = '',
            onChange,
            additionalClasses = '',
            ...rest
        },
        ref
    ) => {
        const isTelType = Boolean(type === 'tel');
        const isEmailType = Boolean(type === 'email');

        const changeHandler = (e) => {
            if (isTelType) {
                const target = e.target;
                target.value = target.value.replace(/[^+/\d]/g, '');
            }
            if (typeof onChange === 'function') {
                onChange(e);
            }
        };

        const hasIcon = ['email', 'tel'].includes(type) && styles.hasIcon;

        return (
            <div className={clsx(styles.control, styles[variant], hasIcon, error && styles.error, additionalClasses)}>
                {label && <Label>{label}</Label>}
                <div className={styles.holder}>
                    <input
                        ref={ref}
                        className={clsx(error && styles.error)}
                        id={id}
                        type={type}
                        name={name}
                        value={value}
                        placeholder={placeholder}
                        {...rest}
                        onChange={changeHandler}
                    />
                    {isEmailType && (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                            <path d="M5.467 3.68a40.797 40.797 0 0 1 7.066 0l1.133.1c.754.065 1.4.514 1.734 1.158a.177.177 0 0 1-.068.23l-4.7 2.91a3.188 3.188 0 0 1-3.299.034l-4.73-2.796a.177.177 0 0 1-.078-.22 2.167 2.167 0 0 1 1.81-1.317l1.132-.098Z" />
                            <path d="M2.521 6.575a.186.186 0 0 0-.28.14 22.763 22.763 0 0 0 .139 5.67c.149.99.957 1.75 1.954 1.836l1.133.098a40.81 40.81 0 0 0 7.066 0l1.133-.098a2.166 2.166 0 0 0 1.954-1.836c.29-1.933.331-3.894.121-5.834a.186.186 0 0 0-.283-.137l-4.233 2.62a4.312 4.312 0 0 1-4.464.046l-4.24-2.505Z" />
                        </svg>
                    )}
                    {isTelType && <img src="/images/🇺🇸-icon.png" className={styles.iconImg} alt="" />}
                </div>
                {Boolean(error && helperText) && <Error>{helperText}</Error>}
            </div>
        );
    }
);

Input.displayName = 'Input';

export default memo(Input);
