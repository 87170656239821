import { memo, forwardRef } from 'react';
import clsx from 'clsx';
import classes from './NavigationButton.module.scss';
import IconCaret from '../Icons/IconCaret';

const NavigationButton = forwardRef(({ reverse, watchOverflow, size, className = 'relative', ...rest }, ref) => {
    return (
        <button
            ref={ref}
            className={clsx(
                classes.btn,
                classes[size],
                reverse && classes.reverse,
                watchOverflow && classes.watchOverflow,
                className
            )}
            type="button"
            {...rest}
        >
            <IconCaret />
        </button>
    );
});

export default memo(NavigationButton);
