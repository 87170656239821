import { useEffect } from 'react';

export const useCloseModalByKeyboard = (opened, close) => {
    useEffect(() => {
        const $html = document.documentElement;
        const closeModalWithKeyboardHandler = (e) => {
            if (e.key !== 'Escape') {
                return;
            }

            close();
        };

        if (opened) {
            $html.addEventListener('keydown', closeModalWithKeyboardHandler, false);
        }

        return () => {
            $html.removeEventListener('keydown', closeModalWithKeyboardHandler, false);
        };
    }, [close, opened]);
};
