// import * as Yup from 'yup';
import { z } from 'zod';
import {
    FIELD_REQUIRED,
    FIELD_EMAIL,
    FIELD_PHONE,
    getMinNumbers,
    getMaxNumbers,
    getMinCharacters,
    getMaxCharacters,
} from '../../constants/errors.constants';

export const nameSchema = z
    .string({ required_error: FIELD_REQUIRED })
    .min(3, getMinCharacters('name', 3))
    .max(50, getMaxCharacters('name', 50));

export const emailSchema = z.string({ required_error: FIELD_REQUIRED }).email(FIELD_EMAIL);

export const phoneSchema = z
    .string({ required_error: FIELD_REQUIRED })
    .regex(/^\+?(?:[0-9] ?){6,14}[0-9]$/, FIELD_PHONE)
    .min(10, getMinNumbers('phone', 10))
    .max(14, getMaxNumbers('phone', 14))
    .nullish();

export const stringSchema = z.string({ required_error: FIELD_REQUIRED }).nullish();

export const subscribeSchema = z.object({
    email: emailSchema,
});
