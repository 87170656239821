import { Link } from 'react-router-dom';
import { memo, forwardRef } from 'react';
import clsx from 'clsx';
import classes from './Button.module.scss';
import IconArrowTop from '../Icons/IconArrowTop';

const Button = forwardRef(
    (
        {
            children,
            isDefaultLink = false,
            type = 'button',
            icon,
            variant = 'primary',
            size,
            href,
            className,
            onClick,
            hasIcon,
            Icon,
            responsive = false,
            ...rest
        },
        ref
    ) => {
        const { disabled } = rest;

        if (isDefaultLink && href) {
            return (
                <a
                    ref={ref}
                    href={href}
                    className={clsx(
                        classes.btn,
                        Boolean(hasIcon || Icon) && classes.hasIcon,
                        responsive && classes.responsive,
                        classes[variant],
                        classes[size],
                        className,
                        disabled && classes.disabled
                    )}
                    rel="noopener noreferrer nofollow noindex"
                    target="__blank"
                    {...rest}
                >
                    <span>{children}</span>
                    {Icon ? <Icon /> : Boolean(hasIcon && !Icon) ? <IconArrowTop className={classes.icon} /> : null}
                </a>
            );
        }

        if (href) {
            return (
                <Link
                    to={href}
                    ref={ref}
                    className={clsx(
                        classes.btn,
                        Boolean(hasIcon || Icon) && classes.hasIcon,
                        responsive && classes.responsive,
                        classes[variant],
                        classes[size],
                        className,
                        disabled && classes.disabled
                    )}
                    {...rest}
                >
                    <span>{children}</span>
                    {Icon ? (
                        <Icon className={classes.icon} />
                    ) : Boolean(hasIcon && !Icon) ? (
                        <IconArrowTop className={classes.icon} />
                    ) : null}
                </Link>
            );
        }

        return (
            <button
                ref={ref}
                className={clsx(
                    classes.btn,
                    Boolean(hasIcon || Icon) && classes.hasIcon,
                    responsive && classes.responsive,
                    classes[variant],
                    classes[size],
                    className
                )}
                type={type}
                onClick={onClick}
                {...rest}
            >
                <span>{children}</span>
                {Icon ? (
                    <Icon className={classes.icon} />
                ) : Boolean(hasIcon && !Icon) ? (
                    <IconArrowTop className={classes.icon} />
                ) : null}
            </button>
        );
    }
);

Button.displayName = 'Button';

export default memo(Button);
