import { useMutation } from '@tanstack/react-query';
import { createHubspotSubmission } from '../../api/hubspot';

export const useCreateHubspotSubmission = () => {
    const mutation = useMutation({
        mutationFn: createHubspotSubmission,
    });

    return mutation;
};
