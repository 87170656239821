import React from 'react';
import { motion } from 'framer-motion';
import { fadeInLeft, fadeInRight } from '../../helpers/utils/animations';
import { ROUTES } from '../../constants/constants';
import { CALCULATOR_SECTION_SUBTITLE_DEFAULT, CALCULATOR_SECTION_TITLE_DEFAULT } from './constants';
import LinkButton from '../LinkButton';
import Container from '../Container';
import s from './CalculatorSection.module.scss';

const CalculatorSection = ({
    title = CALCULATOR_SECTION_TITLE_DEFAULT,
    subtitle = CALCULATOR_SECTION_SUBTITLE_DEFAULT,
    linkTo = ROUTES.quiz,
    noHelperText,
}) => {
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={`${s.calculator} true o-hidden`}
        >
            <Container>
                <div className={s.holder}>
                    <div className={s.content}>
                        <motion.h1 className={s.title} custom={1} variants={fadeInLeft}>
                            {title}
                        </motion.h1>
                        <motion.p custom={2} variants={fadeInLeft} className={s.text}>
                            {subtitle}
                        </motion.p>
                        <motion.div custom={3} variants={fadeInLeft}>
                            <LinkButton
                                classNameHelper={s.helperText}
                                linkTo={linkTo}
                                variant="fourth"
                                hasIcon
                                blueBlock="true"
                                noHelperText={noHelperText}
                            />
                        </motion.div>
                    </div>
                    <motion.div custom={2} variants={fadeInRight} className={s.image}>
                        <img src="./images/calculator.svg" alt={`Ready To Conquer Your Debt?`} />
                    </motion.div>
                </div>
            </Container>
        </motion.section>
    );
};

export default CalculatorSection;
