// import { Suspense, lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ROUTES } from '../../constants/constants';
import WithHeader from '../../HOC/WithHeader';
import CreditRestorationCreditScoreInterestStep from './components/CreditRestorationCreditScoreInterestStep';
import CreditRestorationQuizContactDetailsStep from './components/CreditRestorationQuizContactDetailsStep';
import CreditRestorationQuizReviewOptionsStep from './components/CreditRestorationQuizReviewOptionsStep';
import CreditRestorationQuizTimelineStep from './components/CreditRestorationQuizTimelineStep';
import CreditRestorationQuizGoalStep from './components/CreditRestorationQuizGoalStep';
import CreditRestorationQuizEntry from './components/CreditRestorationQuizEntry';
import QuizStepper from './components/QuizStepper';
import s from './CreditRestorationQuiz.module.scss';

const CreditRestorationQuiz = () => {
    return (
        <motion.section initial="hidden" whileInView="visible" viewport={{ once: true }} key={1} className={s.section}>
            <WithHeader themeDark={true} launchButtonText={'Get started'} launchButtonHref={'/quiz'}>
                <div className="o-hidden">
                    <div className={s.holder}>
                        <Routes>
                            <Route index element={<CreditRestorationQuizEntry />} />
                            <Route
                                path={`${ROUTES.questionnaire}/*`}
                                element={
                                    <>
                                        <div className={s.stepper}>
                                            <QuizStepper />
                                        </div>
                                        <Outlet />
                                        <Routes>
                                            <Route
                                                path={ROUTES.creditScoreInterest}
                                                element={<CreditRestorationCreditScoreInterestStep />}
                                            />
                                            <Route
                                                path={ROUTES.understandingNeeds}
                                                element={<CreditRestorationQuizGoalStep />}
                                            />
                                            <Route
                                                path={ROUTES.timeline}
                                                element={<CreditRestorationQuizTimelineStep />}
                                            />
                                            <Route
                                                path={ROUTES.personalInfo}
                                                element={<CreditRestorationQuizContactDetailsStep />}
                                            />
                                            <Route
                                                path={`${ROUTES.reviewOptions}/*`}
                                                element={<CreditRestorationQuizReviewOptionsStep />}
                                            />
                                        </Routes>
                                    </>
                                }
                            />
                        </Routes>
                    </div>
                </div>
            </WithHeader>
        </motion.section>
    );
};

export default CreditRestorationQuiz;
