import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import { actionsSelector, personalInfoStepSelector, useCreditRestorationQuizStore } from '../../store';
import { ROUTES } from '../../../../constants/constants';
import Container from '../../../Container';
import CreditRestorationQuizLoadingOptions from './components/CreditRestorationQuizLoadingOptions';
import CreditRestorationQuizSuccess from './components/CreditRestorationQuizSuccess';
import CreditRestorationQuizError from './components/CreditRestorationQuizError';
import s from './CreditRestorationQuizReviewOptionsStep.module.scss';

const CreditRestorationQuizReviewOptionsStep = () => {
    const { firstName } = useCreditRestorationQuizStore(personalInfoStepSelector);
    const quizActions = useCreditRestorationQuizStore(actionsSelector);

    useEffect(() => {
        quizActions.setField('currentStepNumber', 6);
    });

    if (!firstName) {
        return <Navigate to={`../${ROUTES.personalInfo}`} replace />;
    }

    return (
        <Container>
            <div className={clsx(s.section, s.sectionHolder)}>
                <AnimatePresence mode="wait">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{
                            opacity: 1,
                            transition: { type: 'linear', duration: 0.2 },
                        }}
                        exit={{ opacity: 0, transition: { type: 'linear', duration: 0.2 } }}
                    >
                        <Routes>
                            <Route index element={<CreditRestorationQuizLoadingOptions />} />
                            <Route path={ROUTES.success} element={<CreditRestorationQuizSuccess />} />
                            <Route path={ROUTES.error} element={<CreditRestorationQuizError />} />
                        </Routes>
                    </motion.div>
                </AnimatePresence>
            </div>
        </Container>
    );
};

export default CreditRestorationQuizReviewOptionsStep;
