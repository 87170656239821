import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Container from '../Container/Container';
import styles from './BlogHero.module.scss';
import WithHeader from '../../HOC/WithHeader/WithHeader';
import Search from '../Search/Search';
import { fadeInUp } from '../../helpers/utils/animations';
import { motion } from 'framer-motion';

const BlogHero = ({ href, image, title, date }) => {
    return (
        <motion.section initial="hidden" whileInView="visible" viewport={{ once: true }} className="o-hidden">
            <div className={clsx(styles.hero, 'blueBlock')}>
                <WithHeader>
                    <Container>
                        <div className={styles.holder}>
                            <div className={styles.content}>
                                <motion.h1 custom={1} variants={fadeInUp}>
                                    <strong>Welcome</strong> to our blog
                                </motion.h1>
                                <motion.p custom={2} variants={fadeInUp} className={styles.subtitle}>
                                    Check out our latest headlines
                                </motion.p>
                            </div>
                            <motion.div custom={3} variants={fadeInUp}>
                                <Search />
                            </motion.div>
                        </div>
                    </Container>
                </WithHeader>
            </div>
            <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} className={styles.previewWrap}>
                <Container>
                    <motion.div variants={fadeInUp} className={styles.previewContainer}>
                        <Link to={href} className={styles.preview}>
                            <div className={styles.previewHolder}>
                                <div className={styles.image}>
                                    <img src={image} alt="" />
                                </div>
                                <div className={styles.previewContent}>
                                    <div className={styles.date}>{date}</div>
                                    <h2>{title}</h2>
                                </div>
                            </div>
                        </Link>
                    </motion.div>
                </Container>
            </motion.div>
        </motion.section>
    );
};

export default BlogHero;
