import PropTypes from 'prop-types';
import s from './Card.module.css';
import Typography from '../../../../../Typography';

const Card = ({ title, subtitle, iconName }) => {
    return (
        <div className={s.wrap}>
            <img
                width={40}
                height={40}
                src={`/images/credit-score-solutions/credit-restoration/${iconName}.svg`}
                alt=""
            />
            <div>
                <Typography className={s.title} variant="title-h4" asChild>
                    <h2>{title}</h2>
                </Typography>
                <Typography className={s.subtitle} variant="paragraph-base" asChild>
                    <p>{subtitle}</p>
                </Typography>
            </div>
        </div>
    );
};

export default Card;

Card.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    iconName: PropTypes.string,
};
