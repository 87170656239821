export const HEADER_NAV = [
    {
        name: 'About Us',
        to: '/about',
    },
    {
        name: 'Debt Solutions',
        to: '/debt-solutions',
    },
    {
        name: 'How We Help',
        to: '/help',
    },
    {
        name: 'Credit Repair',
        to: '/credit-score-solutions',
    },
    // {
    //     name: 'Medical Debt',
    //     to: '/medical-debt',
    // },
    {
        name: 'Blog',
        to: '/blog',
    },
];
