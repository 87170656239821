export const CREDIT_SCORE_SOLUTIONS_OFFERS = [
    {
        title: 'Contact us for your free credit consultation',
        description:
            'Begin with a no-obligation consultation to assess your credit situation. Our experts will provide you with personalized attention from the start.',
        imageName: 'heart',
    },
    {
        title: 'Credit analysis',
        description:
            'Receive a thorough analysis of your credit report to identify areas for improvement and potential strategies for repair.',
        imageName: 'credit-analysis',
    },
    {
        title: 'Establish a game plan',
        description:
            'Work with our specialists to develop a customized plan of action tailored to your unique credit history and goals.',
        imageName: 'establish-plan',
    },
    {
        title: 'Personal information',
        description:
            'We handle your personal information with the utmost security and confidentiality as we work to resolve your credit issues.',
        imageName: 'personal-information',
    },
    {
        title: 'Analysis & confirmation',
        description:
            'Our team conducts a detailed verification process, ensuring that your credit repair strategy is being implemented effectively.',
        imageName: 'confirmation',
    },
    {
        title: 'Time to relax',
        description:
            'Once your personalized credit strategy is in place, you can relax knowing that Triton is working diligently to improve your credit score.',
        imageName: 'relax',
    },
];
