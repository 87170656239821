import { memo, forwardRef } from 'react';
import clsx from 'clsx';
import classes from './RadioButton.module.scss';

const RadioButton = forwardRef(({ name, value, label = 'Text', size, className, helperText, error, ...rest }, ref) => {
    return (
        <label className={clsx(classes.holder, classes[size], error && classes.error, className)}>
            <input type="radio" name={name} value={value} ref={ref} {...rest} />
            <span className={classes.control}>
                <span>{label}</span>
                <span className={classes.iconWrap}>
                    <svg className={classes.icon} viewBox="0 0 9 8" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 4.6L3.57895 7L8 1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
            </span>
        </label>
    );
});

RadioButton.displayName = 'RadioButton';

export default memo(RadioButton);
