import { memo } from 'react';
import classes from './Label.module.scss';

const Label = ({ component = 'label', htmlFor, children }) => {
    const CustomTag = component;
    return (
        <CustomTag htmlFor={htmlFor} className={classes.label}>
            {children}
        </CustomTag>
    );
};

export default memo(Label);
