import Container from '../Container/Container';
import clsx from 'clsx';
import styles from './DebtTypes.module.scss';
import MarkBlock from '../MarkBlock/MarkBlock';
import { MARKS_CHECKED, MARKS_NO_CHECKED } from './constants';
import { fadeInLeft, fadeInUp } from '../../helpers/utils/animations';
import { motion } from 'framer-motion';

const DebtTypes = ({
    title = (
        <>
            <strong>What debt types</strong> can we help you with?
        </>
    ),
}) => {
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(styles.debtTypes, 'o-hidden')}
        >
            <Container>
                <motion.h2 custom={1} variants={fadeInLeft}>
                    {title}
                </motion.h2>
                <motion.p custom={2} variants={fadeInLeft} className={styles.subtitle}>
                    See what kinds of debt types Triton can help you overcome
                </motion.p>
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} className={styles.holder}>
                    <motion.div variants={fadeInUp} className={clsx(styles.item, styles.checked)}>
                        {MARKS_CHECKED.map((item, i) => (
                            <MarkBlock checked={true} text={item.text} key={i} />
                        ))}
                    </motion.div>
                    <motion.div variants={fadeInUp} className={styles.item}>
                        {MARKS_NO_CHECKED.map((item, i) => (
                            <MarkBlock checked={false} text={item.text} key={i} />
                        ))}
                    </motion.div>
                </motion.div>
            </Container>
        </motion.section>
    );
};

export default DebtTypes;
