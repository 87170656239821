import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { formattedDate } from '../../helpers/utils/utils';
import WithHeader from '../../HOC/WithHeader';
import Container from '../Container';
import IconCaret from '../ui/Icons/IconCaret';
import s from './ArticleHero.module.scss';

const ArticleHero = ({ image, title, date, chip }) => {
    const navigate = useNavigate();

    return (
        <section className={s.hero}>
            <WithHeader>
                <div className={s.image}>
                    <img src={image} alt="" />
                </div>
                <Container>
                    <div className={s.holder}>
                        <div className={s.content}>
                            <button className={clsx(s.btn, 'back-link')} onClick={() => navigate(-1)}>
                                <IconCaret />
                                Back to the blog
                            </button>
                            <h1 className={clsx(s.title, 'h1')}>{title}</h1>
                            <p className={s.date}>
                                Published {formattedDate(date)} {new Date(date).toLocaleTimeString()}
                            </p>
                            <div className={s.chip}>Section: {chip}</div>
                        </div>
                    </div>
                </Container>
            </WithHeader>
        </section>
    );
};

export default ArticleHero;
