import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { middlewares } from '../../store/middlewares';
import {
    QUIZ_DEBT_TYPES,
    STEPS_COUNT,
    QUIZ_TERMS_TYPES,
    QUIZ_ESTIMATED_CREDIT_SCORE_TYPES,
    QUIZ_FINANCING_AMOUNT_TYPES,
    QUIZ_STEP_CURRENT,
    QUIZ_STEP_INACTIVE,
    QUIZ_GOAL_TYPES,
} from './constants';
import { ROUTES } from '../../constants/constants';

export const QUIZ_STORE_INITIAL_CONFIG = {
    // NOTE: ???
    scrollTo: 0,
    currentStepNumber: STEPS_COUNT[ROUTES.understandingNeeds].step,
    currentStepLabel: STEPS_COUNT[ROUTES.understandingNeeds].label,
    currentStepId: STEPS_COUNT[ROUTES.understandingNeeds].step_name,
    stepOneActiveSubStep: QUIZ_STEP_CURRENT,
    stepTwoActiveSubStep: QUIZ_STEP_INACTIVE,
    stepThreeActiveSubStep: QUIZ_STEP_INACTIVE,
    showPane: false,
};

export const QUIZ_STORE_INITIAL_UNDERSTANDING_NEEDS_STEP_STATE = {
    goalType: QUIZ_GOAL_TYPES.improveCredit.value,
    debtType: QUIZ_DEBT_TYPES.creditCard.value,
    termsType: QUIZ_TERMS_TYPES.lowestMonthlyPayment.value,
    estimatedCreditScore: QUIZ_ESTIMATED_CREDIT_SCORE_TYPES.poor.value,
    financingAmount: QUIZ_FINANCING_AMOUNT_TYPES.from1Kto10K.value,
    annualIncome: 0,
    isAnnualIncomeStepValid: true,
};

export const QUIZ_STORE_INITIAL_PERSONAL_INFO_STEP_STATE = {
    // State 1
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    isNameDataStepValid: false,
    // State 2
    state: undefined,
    city: undefined,
    address: '',
    zip: '',
    isAddressStepValid: false,
};

// /**
//  * @typedef {{ annualIncome: number | undefined }} QuizAnnualIncomeStepState
//  * @type {QuizAnnualIncomeStepState}
//  */
// export const QUIZ_STORE_INITIAL_ANNUAL_INCOME_STEP_STATE = {
//   annualIncome: undefined
// }

export const QUIZ_STORE_INITIAL_SCHEDULE_STEP_STATE = {
    scheduleDate: '',
    scheduleTime: '',
};

export const QUIZ_STORE_INITIAL_CREDIT_SCORE = {
    creditScore: {
        min: 0,
        max: 0,
    },
    unsecuredBalance: {
        min: 0,
        max: 0,
    },
};

export const QUIZ_STORE_INITIAL_OFFER_URL = {
    offerRedirectUrl: '',
};

export const QUIZ_STORE_INITIAL_CREDIT_RESTORATION_STATE = {
    creditScoreInterest: '',
    creditGoals: '',
    motivation: '',
};

export const QUIZ_STORE_INITIAL_STATE = {
    ...QUIZ_STORE_INITIAL_CONFIG,
    // Understanding needs step
    ...QUIZ_STORE_INITIAL_UNDERSTANDING_NEEDS_STEP_STATE,
    // Personal info step
    ...QUIZ_STORE_INITIAL_PERSONAL_INFO_STEP_STATE,
    // // Annual Income step
    // ...QUIZ_STORE_INITIAL_ANNUAL_INCOME_STEP_STATE,
    // Schedule Step
    ...QUIZ_STORE_INITIAL_SCHEDULE_STEP_STATE,
    // Credit Score
    ...QUIZ_STORE_INITIAL_CREDIT_SCORE,
    // Offer URL
    ...QUIZ_STORE_INITIAL_OFFER_URL,
    // Credit Restoration
    ...QUIZ_STORE_INITIAL_CREDIT_RESTORATION_STATE,
};

const createStore = persist(
    (set) => {
        return {
            ...QUIZ_STORE_INITIAL_STATE,
            /**
             * @param {keyof typeof QUIZ_STORE_INITIAL_STATE} key
             * @param {(typeof QUIZ_STORE_INITIAL_STATE)[keyof typeof QUIZ_STORE_INITIAL_STATE]} value
             */
            setField(key, value) {
                set(
                    (state) => {
                        state[key] = value;
                        return state;
                    },
                    false,
                    {
                        type: 'quiz/setField',
                        payload: {
                            key,
                            value,
                        },
                    }
                );
            },
            /**
             * @param {Partial<typeof QUIZ_STORE_INITIAL_STATE>} values
             */
            setFields: (values) => {
                set(
                    (state) => {
                        state = Object.assign(state, values);
                        return state;
                    },
                    false,
                    {
                        type: 'quiz/setFields',
                        payload: values,
                    }
                );
            },
            cleanUp: () => {
                set(QUIZ_STORE_INITIAL_STATE, false, {
                    type: 'quiz/cleanUp',
                });
            },
        };
    },
    {
        name: 'quiz-store',
        storage: createJSONStorage(() => {
            return sessionStorage;
        }),
    }
);

export const useQuizStore = create()(middlewares('quiz-store', immer(createStore)));

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof QUIZ_STORE_INITIAL_CONFIG}
 */
export const configSelector = (state) => {
    return {
        scrollTo: state.scrollTo,
        currentStepNumber: state.currentStepNumber,
        currentStepLabel: state.currentStepLabel,
        currentStepId: state.currentStepId,
        subSteps: state.subSteps,
        stepOneActiveSubStep: state.stepOneActiveSubStep,
        stepTwoActiveSubStep: state.stepTwoActiveSubStep,
        stepThreeActiveSubStep: state.stepThreeActiveSubStep,
        showPane: state.showPane,
    };
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof QUIZ_STORE_INITIAL_UNDERSTANDING_NEEDS_STEP_STATE}
 */
export const understandingNeedsStepSelector = (state) => {
    return {
        goalType: state.goalType,
        debtType: state.debtType,
        termsType: state.termsType,
        estimatedCreditScore: state.estimatedCreditScore,
        financingAmount: state.financingAmount,
        annualIncome: state.annualIncome,
        isAnnualIncomeStepValid: state.isAnnualIncomeStepValid,
    };
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof QUIZ_STORE_INITIAL_PERSONAL_INFO_STEP_STATE}
 */
export const personalInfoStepSelector = (state) => {
    return {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        phone: state.phone,
        isNameDataStepValid: state.isNameDataStepValid,
        state: state.state,
        city: state.city,
        address: state.address,
        zip: state.zip,
        isAddressStepValid: state.isAddressStepValid,
    };
};

// /**
//  * @param {ReturnType<typeof createStore>} state
//  * @returns {typeof QUIZ_STORE_INITIAL_ANNUAL_INCOME_STEP_STATE}
//  */
// export const annualIncomeStepSelector = (state) => {
//   return {
//     annualIncome: state.annualIncome,
//   }
// }

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof QUIZ_STORE_INITIAL_SCHEDULE_STEP_STATE  }
 */
export const scheduleSelector = (state) => {
    return {
        scheduleDate: state.scheduleDate,
        scheduleTime: state.scheduleTime,
    };
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof QUIZ_STORE_INITIAL_CREDIT_SCORE}
 */
export const creditScoreSelector = (state) => {
    return {
        creditScore: state.creditScore,
        unsecuredBalance: state.unsecuredBalance,
    };
};

/**
 *
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof QUIZ_STORE_INITIAL_OFFER_URL}
 */
export const offerUrlSelector = (state) => {
    return {
        offerRedirectUrl: state.offerRedirectUrl,
    };
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof QUIZ_STORE_INITIAL_STATE}
 */
export const allFieldsSelector = (state) => {
    const { setField: _, setFields: __, cleanUp: ___, ...rest } = state;

    return rest;
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {Pick<ReturnType<typeof createStore>, 'cleanUp' | 'setField' | 'setFields'>}
 */
export const actionsSelector = (state) => {
    return {
        cleanUp: state.cleanUp,
        setField: state.setField,
        setFields: state.setFields,
    };
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof QUIZ_STORE_INITIAL_CREDIT_RESTORATION_STATE}
 */
export const creditRestorationSelector = (state) => {
    return {
        creditScoreInterest: state.creditScoreInterest,
        creditGoals: state.creditGoals,
        motivation: state.motivation,
    };
};
