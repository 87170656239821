import { fadeInUp } from '../../../../helpers/utils/animations';
import Container from '../../../Container';
import { configSelector, useCreditRestorationQuizStore } from '../../store';
import { motion } from 'framer-motion';
import s from './QuizStepper.module.scss';
import { CREDIT_RESTORATION_QUIZ_STEPS } from '../../constants';
import clsx from 'clsx';
import QuizStepperProgress from './components/QuizStepperProgress';

const QuizStepper = () => {
    const { currentStepNumber } = useCreditRestorationQuizStore(configSelector);

    return (
        <Container>
            <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} key="stepper-1">
                <motion.div variants={fadeInUp} className={s.wrap}>
                    <div
                        className={clsx(s.top, s.items)}
                        style={{
                            gridTemplateColumns: `repeat(${Object.keys(CREDIT_RESTORATION_QUIZ_STEPS).length}, minmax(0, 1fr))`,
                        }}
                    >
                        {Object.values(CREDIT_RESTORATION_QUIZ_STEPS).map((_, index) => {
                            return (
                                <QuizStepperProgress
                                    key={index}
                                    stepIndex={index}
                                    activeStepIndex={currentStepNumber - 1}
                                />
                            );
                        })}
                    </div>
                    <div className={s.holder}>
                        <div className={s.step}>
                            <strong>Step {currentStepNumber} - </strong>{' '}
                            {Object.values(CREDIT_RESTORATION_QUIZ_STEPS)[currentStepNumber - 1].label}
                        </div>
                        <div className={s.text}>
                            {Object.keys(CREDIT_RESTORATION_QUIZ_STEPS).length - currentStepNumber}{' '}
                            {Object.keys(CREDIT_RESTORATION_QUIZ_STEPS).length - currentStepNumber === 1
                                ? 'step'
                                : 'steps'}{' '}
                            to complete
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </Container>
    );
};

export default QuizStepper;
