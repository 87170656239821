import { memo, forwardRef } from 'react';

const Icon = forwardRef(({ id, className }, ref) => {
    return (
        <svg className={className} ref={ref}>
            <use href={`/images/sprite.svg#${id}`} />
        </svg>
    );
});

Icon.displayName = 'Icon';

export default memo(Icon);
