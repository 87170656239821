import React from 'react';
import DoubleSection from '../DoubleSection';
import { ROUTES } from '../../../../constants/constants';
import { CREDIT_SCORE_SOLUTIONS_DOUBLE_SECTIONS_ITEMS } from '../../constants';
import s from './ImageAndTextSections.module.css';

const ImageAndTextSections = () => {
    return (
        <div className={s.wrap}>
            {CREDIT_SCORE_SOLUTIONS_DOUBLE_SECTIONS_ITEMS.map((props, i) => (
                <DoubleSection key={i} isReversed {...props} linkTo={`/${ROUTES.creditRestorationQuiz}`} />
            ))}
        </div>
    );
};

export default ImageAndTextSections;
