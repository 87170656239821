import { useState, useCallback, useEffect } from 'react';
import { useEffectEvent } from './useEffectEvent';

export const useLocalStorage = (key, initialValue) => {
    const readValue = useCallback(() => {
        const item = localStorage.getItem(key);

        try {
            return item ? JSON.parse(item) : initialValue;
        } catch (_error) {
            return item ? item : initialValue;
        }
    }, [initialValue, key]);

    const [storedValue, setStoredValue] = useState(readValue);

    const setValueEffectEvent = useEffectEvent((value) => {
        const newValue = value instanceof Function ? value(storedValue) : value;

        if (newValue || typeof newValue === 'string' || typeof newValue === 'number') {
            localStorage.setItem(key, typeof newValue === 'object' ? JSON.stringify(newValue) : String(newValue));
        } else {
            localStorage.removeItem(key);
        }

        setStoredValue(newValue);
    });

    useEffect(() => {
        setStoredValue(readValue());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [storedValue, setValueEffectEvent];
};
