import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { actionsSelector, understandingNeedsStepSelector, useCreditRestorationQuizStore } from '../../store';
import { fadeInLeft } from '../../../../helpers/utils/animations';
import { CREDIT_RESTORATION_CREDIT_GOALS_OPTIONS, ROUTES } from '../../../../constants/constants';
import IconArrowRight from '../../../ui/Icons/IconArrowRight';
import RadioButton from '../../../ui/RadioButton';
import Typography from '../../../Typography';
import Container from '../../../Container';
import Button from '../../../ui/Button';
import s from './CreditRestorationQuizGoalStep.module.css';

const CreditRestorationQuizGoalStep = () => {
    const { creditGoals } = useCreditRestorationQuizStore(understandingNeedsStepSelector);
    const quizActions = useCreditRestorationQuizStore(actionsSelector);

    const navigate = useNavigate();

    const optionChangeHandler = useCallback(
        (newValue) => {
            return () => {
                quizActions.setField('creditGoals', newValue);
            };
        },
        [quizActions]
    );

    const backClickHandler = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        quizActions.setField('currentStepNumber', 2);
    }, [quizActions]);

    return (
        <Container>
            <motion.section className={s.wrap} custom={1} variants={fadeInLeft}>
                <Typography className={s.title} variant="heading-h2" asChild>
                    <h3>
                        What are your&nbsp;<strong>credit goals</strong>?
                    </h3>
                </Typography>
                <div className={s['options-wrap']}>
                    {CREDIT_RESTORATION_CREDIT_GOALS_OPTIONS.map((option) => {
                        return (
                            <RadioButton
                                key={option}
                                className={s.option}
                                checked={creditGoals === option}
                                label={option}
                                value={option}
                                onChange={optionChangeHandler(option)}
                            />
                        );
                    })}
                </div>
                <footer className={s.footer}>
                    <Button className={s.cta} variant="sixth" responsive onClick={backClickHandler}>
                        Back
                    </Button>
                    <Button
                        className={s.cta}
                        href={`../${ROUTES.timeline}`}
                        disabled={!creditGoals}
                        responsive
                        Icon={IconArrowRight}
                    >
                        Next
                    </Button>
                </footer>
            </motion.section>
        </Container>
    );
};

export default CreditRestorationQuizGoalStep;
