import { motion } from 'framer-motion';
import { CREDIT_SCORE_SOLUTIONS_OFFERS } from './constants';
import { fadeInUp } from '../../../../helpers/utils/animations';
import Typography from '../../../Typography';
import Container from '../../../Container';
import CreditScoreSolutionsOfferCard from './components/CreditScoreSolutionOfferCard';
import s from './CreditScoreOffers.module.css';

const CreditScoreOffers = () => {
    return (
        <Container className={s.wrap} size="lg">
            <section className={s.content}>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={fadeInUp}
                    transition={{
                        duration: 0.2,
                        ease: 'easeInOut',
                    }}
                    className={s['title-wrap']}
                >
                    <Typography className={s.title} variant="heading-h4">
                        <h2>
                            Triton is a company that <strong>focuses on the customer</strong>
                        </h2>
                    </Typography>
                    <Typography className={s['subtitle']} variant="heading-h5">
                        <span>
                            What do you also get when you work with Triton Finance? Triton - a company you can trust
                        </span>
                    </Typography>
                </motion.div>
                <div className={s.services}>
                    {CREDIT_SCORE_SOLUTIONS_OFFERS.map((offer, index) => {
                        return (
                            <CreditScoreSolutionsOfferCard
                                stepNumber={index + 1}
                                title={offer.title}
                                description={offer.description}
                                imageName={offer.imageName}
                            />
                        );
                    })}
                </div>
            </section>
        </Container>
    );
};

export default CreditScoreOffers;
