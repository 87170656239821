import { Link } from 'react-router-dom';
import styles from './PostRecent.module.scss';
import { formattedDate } from '../../helpers/utils/utils';

const PostRecentItem = ({ id, image, title, subtitle, date, chip, className }) => {
    return (
        <div className={className}>
            {image && (
                <div className={styles.image}>
                    <img src={image} alt={title} />
                    <div className={styles.chip}>Section: {chip}</div>
                </div>
            )}
            <div className={styles.content}>
                <p className={styles.date}>{formattedDate(date, false)}</p>

                <Link to={`/blog/${id}`}>
                    <h4>{title}</h4>
                </Link>
                <div className={styles.desc}>
                    <p>{subtitle}</p>
                </div>
            </div>
            <div className={styles.chip}>Section: {chip}</div>
        </div>
    );
};

export default PostRecentItem;
