import { tritonFormsHttp } from './axios';

export const ARRAY_TO_HUBSPOT_SUCCESS_MESSAGE = 'API ran successfully!';

/**
 *
 * @param {{
 *  firstname: string,
 *  lastname: string,
 *  address: string,
 *  city: string,
 *  state: string,
 *  zip: string,
 *  email: string,
 * } & Record<string, string>} data
 */
export const arrayToHubspot = async (data, config) => {
    const response = await tritonFormsHttp.post('/array-to-hubspot', data, config);

    return response.data;

    // ! NOTE: For mock testing purposes ONLY
    // return new Promise((resolve) => {
    //     return resolve({
    //         status: 200,
    //         creditScore: {
    //             min: 630,
    //             max: 635,
    //         },
    //         unsecuredBalance: {
    //             min: 22_000,
    //             max: 40_000,
    //         },
    //     });
    // });
};
