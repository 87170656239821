import clsx from 'clsx';
import { useContext } from 'react';
import ArticleSection from '../../components/Article/ArticleSection';
import CalculatorSection from '../../components/CalculatorSection/CalculatorSection';
import TopContentBlock from '../../components/TopContentBlock/TopContentBlock';
import TheeColumnSection from '../../components/ThreeColumnsSection/TheeColumnSection';
import { ROUTES } from '../../constants/constants';
import { whyTriton } from '../../config/whyTriton';
import { tritonFocus } from '../../config/tritonFocus';
import { AppContext } from '../../App';
import SolveProblems from '../../components/SolveProblems/SolveProblems';
import styles from './WeHelp.module.scss';

const WeHelp = () => {
    const { articles } = useContext(AppContext);

    return (
        <>
            <TopContentBlock
                titleBold="Saving you money and reducing interest"
                titleRest="with smart debt solutions"
                subtitle="See how we help everyday people lower interest, save money, and pay off debt quicker"
                image="./images/svg/laptop-money.svg"
                imageMob="./images/svg/laptop-money-mob.svg"
                variant="secondary"
            />
            <SolveProblems />

            <section className={clsx(styles.whyTriton, 'o-hidden')}>
                <TheeColumnSection
                    columnsData={whyTriton}
                    titleBold="Why choose"
                    titleRest="Triton Finance?"
                    subtitle="See what makes Triton a leader in debt solutions"
                />
            </section>

            <section className={styles.tritonFocus}>
                <TheeColumnSection
                    columnsData={tritonFocus}
                    titleBold="Triton is a company that"
                    titleRest="focuses on you"
                    variant="secondary"
                />
            </section>

            <ArticleSection
                titleBold="Here's some of"
                titleRest="our latest headlines"
                subtitle="Want to learn more about overcoming debt? Check out our blog"
                numberOfArticles={3}
                linkTo={ROUTES.blog}
                linkName="Visit our blog"
                articles={articles}
            />

            <CalculatorSection subtitle="Let us help you get approved for the smartest debt solution from a nationwide network of lenders. No hassle. No credit impact." />
        </>
    );
};

export default WeHelp;
