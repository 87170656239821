import { useArrayToHubspotAsQuery } from '../../../../../hooks/api/useTritonFormsApi';
import { allFieldsSelector, useCreditRestorationQuizStore } from '../../../store';

export const useCreditRestorationArrayToHubspot = () => {
    const { firstName, lastName, address, city, state, zip, email } = useCreditRestorationQuizStore(allFieldsSelector);

    return useArrayToHubspotAsQuery({
        firstname: firstName,
        lastname: lastName,
        address,
        city,
        state,
        zip,
        email,
    });
};
