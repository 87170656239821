import React from 'react';
import { motion } from 'framer-motion';
import { fadeInUp } from '../../../../helpers/utils/animations';
import { ROUTES } from '../../../../constants/constants';
import IconArrowRight from '../../../ui/Icons/IconArrowRight';
import Button from '../../../ui/Button/Button';
import s from './CreditRestoration.module.scss';
import CreditRestorationProgressChart from './components/CreditRestorationProgressChart';

const CreditRestoration = () => {
    return (
        <section className={s.wrap}>
            <header className={s.header}>
                <motion.h2 custom={1} variants={fadeInUp} className={s.title}>
                    Triton <strong>Credit Restoration</strong>
                </motion.h2>
                <motion.p custom={2} variants={fadeInUp} className={s.subtitle}>
                    Description of average customer results and time spent in the program plus more details about how it
                    works
                </motion.p>
            </header>
            <CreditRestorationProgressChart />
            <div className={s.cta}>
                <Button Icon={IconArrowRight} responsive href={`/${ROUTES.creditRestorationQuiz}`}>
                    Get Started
                </Button>
            </div>
        </section>
    );
};

export default CreditRestoration;
