import styles from './TripleSection.module.scss';

const TripleSectionCard = ({ image, title, desc, index }) => {
    return (
        <div className={styles.card}>
            <div className={styles.image}>
                <img src={image} alt="" width={120} height={80} />
            </div>
            <p className={styles.name}>
                {index}. {title}
            </p>
            <p className={styles.desc}>{desc}</p>
        </div>
    );
};

export default TripleSectionCard;
