import { memo } from 'react';
import { useGetCreditScoreProgress } from '../../../../../Quiz/components/QuizReviewOptionsStep/components/QuizWithInstructionFlow/hooks/useGetCreditScoreProgress';
import ProgramResultChart from '../../../../../Quiz/components/QuizReviewOptionsStep/components/QuizWithInstructionFlow/ProgramResultChart';
import { CREDIT_SCORE_SOLUTION_CHART_COLOR } from '../../../../../Quiz/components/QuizReviewOptionsStep/components/QuizWithInstructionFlow/ProgramResultChart/constants';
import { CREDIT_RESTORATION_STATS } from '../../constants';
import CreditRestorationCard from '../Card';
import s from './CreditRestorationProgressChart.module.scss';

const CreditRestorationProgressChart = () => {
    const chartData = useGetCreditScoreProgress();

    return (
        <>
            <div className={s['chart-wrap']}>
                <ProgramResultChart
                    className={s.chart}
                    data={chartData}
                    chartLineColor={CREDIT_SCORE_SOLUTION_CHART_COLOR}
                />
            </div>
            <div className={s['cards-wrap']}>
                {CREDIT_RESTORATION_STATS.map((stat) => {
                    return <CreditRestorationCard title={stat.value} subtitle={stat.text} iconName={stat.icon} />;
                })}
            </div>
        </>
    );
};

export default memo(CreditRestorationProgressChart);
