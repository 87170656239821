export const ABOUT_DOUBLE_SECTIONS_ITEMS = [
    {
        image: './images/svg/graffic.svg',
        imageMob: './images/svg/graffic-mob.svg',
        titleBold: 'Loans',
        titleRest: 'Our',
        text: 'To empower everyday people with the guidance and tools to reduce their interest cost, save money, and become debt-free',
        imagePosition: 'right',
    },
    {
        image: './images/svg/persona.svg',
        imageMob: './images/svg/persona-mob.svg',
        titleBold: 'we help?',
        titleRest: 'Who',
        text: 'Triton Finance focuses on helping individuals consolidate high-interest debt or those seeking expert guidance to get their best loan approval terms',
        follow: 'link',
    },
];
