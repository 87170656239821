import { useContext } from 'react';
import ArticleSection from '../../components/Article/ArticleSection';
import CalculatorSection from '../../components/CalculatorSection/CalculatorSection';
import DoubleSection from '../../components/DoubleSection/DoubleSection';
import TopContentBlock from '../../components/TopContentBlock/TopContentBlock';
import { AppContext } from '../../App';
import { ROUTES } from '../../constants/constants';
import { ABOUT_DOUBLE_SECTIONS_ITEMS } from './constants';
import WhoWeAre from '../../components/WhoWeAre/WhoWeAre';
import History from '../../components/History/History';
import s from './AboutUs.module.scss';

const AboutUs = () => {
    const { articles } = useContext(AppContext);

    return (
        <>
            <TopContentBlock
                titleBold="We build bridges"
                titleRest="to financial freedom"
                subtitle="Triton's network of over 300 lenders and expert consultants will help you get approved for the best loan to overcome high-interest debt"
                image="./images/svg/bridge.svg"
                imageMob="./images/svg/bridge-mob.svg"
                variant="secondary"
            />
            <WhoWeAre />
            <div className={s.doubleSections}>
                {ABOUT_DOUBLE_SECTIONS_ITEMS.map((props, i) => (
                    <DoubleSection key={i} {...props} variant="secondary" />
                ))}
            </div>
            <History />

            <ArticleSection
                titleBold="Here's some of"
                titleRest="our latest headlines"
                subtitle="Want to learn more about overcoming debt? Check out our blog"
                numberOfArticles={3}
                linkTo={ROUTES.blog}
                linkName="Visit our blog"
                articles={articles}
            />

            <CalculatorSection subtitle="Let us help you get approved for the smartest debt solution from a nationwide network of lenders. No hassle. No credit impact." />
        </>
    );
};

export default AboutUs;
