const IconNone = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
            <path
                fill="#E26464"
                fillRule="evenodd"
                d="M20 4.444C11.422 4.444 4.444 11.422 4.444 20S11.422 35.555 20 35.555 35.556 28.578 35.556 20 28.578 4.444 20 4.444Zm6.967 14.178a1.378 1.378 0 1 1 0 2.756H13.033a1.378 1.378 0 1 1 0-2.756h13.934Z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default IconNone;
