import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '../Typography';
import s from './Card.module.css';

const Card = ({ className, label, value, valueClassName }) => {
    return (
        <dl className={clsx(s.wrap, className)}>
            <div className={s.inner}>
                <Typography className={s.label} variant="button-text" asChild>
                    <dt>{label}</dt>
                </Typography>
                <Typography className={clsx(s.value, valueClassName)} variant="heading-h2" asChild>
                    <dd>{value}</dd>
                </Typography>
            </div>
        </dl>
    );
};

Card.propTypes = {
    className: PropTypes.string,
    valueClassName: PropTypes.string,
    label: PropTypes.node.isRequired,
    value: PropTypes.node.isRequired,
};

export default memo(Card);
