import { memo } from 'react';
import clsx from 'clsx';
import s from './StatisticItem.module.css';
import Typography from '../../../Typography';

const StatisticItem = ({ className, title, description, image }) => {
    return (
        <dl className={clsx(s.wrap, className)}>
            <dt className={s['title-wrap']}>
                {image && <img className={s.image} src={`/images/svg/${image}.svg`} alt={title} />}
                <Typography className={s.title} variant="paragraph-base" asChild>
                    {title}
                </Typography>
                {description && (
                    <Typography className={s.description} variant="paragraph-base" asChild>
                        {description}
                    </Typography>
                )}
            </dt>
        </dl>
    );
};

export default memo(StatisticItem);
