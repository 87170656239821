const IconOptionSelected = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
            <path
                fill="#4C76EB"
                fillRule="evenodd"
                d="M2 9c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7-7-3.14-7-7Zm6.756 2.308 3.792-3.791a.583.583 0 1 0-.825-.825l-3.38 3.379L6.86 8.588a.583.583 0 1 0-.824.824l1.895 1.896a.582.582 0 0 0 .825 0Z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default IconOptionSelected;
