import axios from 'axios';

export const hubspotFormHttp = axios.create({
    baseURL: 'https://api.hsforms.com',
});

export const emailHttp = axios.create({
    baseURL: 'https://api.triton.finance',
});

export const tritonFormsHttp = axios.create({
    baseURL: 'https://forms.triton.finance',
});

export const inboundProspectHttp = axios.create({
    // baseURL: "https://staging.api.inboundprospect.com",
    baseURL: 'https://api.inboundprospect.com',
});

export const tritonOracleHttp = axios.create({
    baseURL: 'https://us-central1-triton-oracle.cloudfunctions.net',
});

// TODO: It's temporarily. For fast testing requests
// const test = async () => {
//     await axios.get('https://api.hubapi.com/crm/v3/objects', {
//         headers: {
//             'Access-Control-Allow-Origin': '*'
//         }
//     });
// }

// test();
