import WithHeader from '../../HOC/WithHeader';
import Container from '../Container';
import Button from '../ui/Button';
import s from './NotFound.module.scss';

const NotFound = () => {
    return (
        <div className={`${s.root} blueBlock`}>
            <WithHeader>
                <div className={s.content}>
                    <Container>
                        <h1 className="h1">
                            <span className={s.green}>Oh No!</span> Error 404
                        </h1>
                        <p className={s.subtitle}>This page you requested could not be found</p>
                        <div className={s.actions}>
                            <Button href="/" hasIcon variant="secondary" responsive>
                                Back To Homepage
                            </Button>
                            <Button href="/" variant="secondary" responsive>
                                Back To Homepage
                            </Button>
                            <Button href="https://google.com" isDefaultLink variant="secondary" responsive>
                                Visit Help Center
                            </Button>
                        </div>
                    </Container>
                </div>
            </WithHeader>
        </div>
    );
};

export default NotFound;
