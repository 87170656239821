import { memo } from 'react';
import ContentModal from '../ContentModal/ContentModal';
import styles from './MessageModal.module.scss';
import Button from '../ui/Button/Button';

const MessageModal = ({ opened, close, message }) => {
    return (
        <ContentModal opened={opened} close={close}>
            <div className={styles.body}>
                <div style={{ fontSize: '22px' }}>{message}</div>
                <Button onClick={close} className={styles.action}>
                    OK
                </Button>
            </div>
        </ContentModal>
    );
};

export default memo(MessageModal);
