import { useMutation, useQuery } from '@tanstack/react-query';
import { arrayToHubspot } from '../../api/tritonForms';
import { ONE_MINUTE } from '../../constants/constants';

export const useArrayToHubspot = () => {
    const mutation = useMutation({
        mutationFn: arrayToHubspot,
    });

    return mutation;
};

/**
 *
 * @param {{
 *  firstname: string,
 *  lastname: string,
 *  address: string,
 *  city: string,
 *  state: string,
 *  zip: string,
 *  email: string,
 * } & Record<string, string>} data
 */
export const useArrayToHubspotAsQuery = (data) => {
    return useQuery({
        queryKey: ['arrayToHubspot', data],
        queryFn({ signal }) {
            return arrayToHubspot(data, { signal });
        },
        staleTime: 5 * ONE_MINUTE,
    });
};
