export const tritonFocus = [
    {
        id: 'f1',
        title: 'A human approach',
        text: "Watch a real person's success story after working with our expert consultants",
        image: './images/svg/approach.svg',
        imageMob: './images/svg/approach-mob.svg',
    },
    {
        id: 'f2',
        title: 'Personalized solutions',
        text: 'We will work with you to customize a plan that fits your life today and helps you achieve what you want to be tomorrow',
        image: './images/svg/solutions.svg',
        imageMob: './images/svg/solutions-mob.svg',
    },
    {
        id: 'f3',
        title: 'Financial literacy',
        text: "We'll help you learn to manage money with more confidence and leverage different options to save money while paying down debt",
        image: './images/svg/literacy.svg',
        imageMob: './images/svg/literacy-mob.svg',
    },
];
