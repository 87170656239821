const IconInstagram = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
            <path d="M9 6.562a2.437 2.437 0 1 0 0 4.875 2.437 2.437 0 0 0 0-4.875Z" />
            <path
                fillRule="evenodd"
                d="M5.077 2.311a35.604 35.604 0 0 1 7.846 0 3.105 3.105 0 0 1 2.74 2.71c.308 2.644.308 5.314 0 7.958a3.105 3.105 0 0 1-2.74 2.71c-2.586.289-5.26.289-7.846 0a3.105 3.105 0 0 1-2.74-2.71 34.252 34.252 0 0 1 0-7.958 3.105 3.105 0 0 1 2.74-2.71ZM12.75 4.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM5.438 9a3.562 3.562 0 1 1 7.125 0 3.562 3.562 0 0 1-7.125 0Z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default IconInstagram;
