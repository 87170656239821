import { useState } from 'react';
import Container from '../Container/Container';
import { STEPS } from './constants';
import { Widget } from '@typeform/embed-react';
import WithHeader from '../../HOC/WithHeader/WithHeader';
import IconDone from '../ui/Icons/IconDone';
import Button from '../ui/Button/Button';
import s from './Quiz.module.scss';

const Quiz = () => {
    const [questionActive, setQuestionActive] = useState(false);

    return (
        <section className={s.section}>
            <WithHeader themeDark={true} launchButtonText={'Go Home'} launchButtonHref={'/'}>
                <Container>
                    {!questionActive ? (
                        <>
                            <div className={s.title}>
                                <h1>
                                    Get help <strong>with your debt today</strong>
                                </h1>
                            </div>

                            <div className={s.subtitle}>
                                <h4>Find out how three simple steps can help you finally pay your debt off</h4>
                            </div>

                            <div className={s.holder}>
                                {STEPS.map((item, i) => (
                                    <div className={s.card} key={i}>
                                        <div className={s.cardHeader}>
                                            <IconDone className={s.icon} />
                                            <div className={s.cardTitle}>
                                                <h3>{item.title}</h3>
                                            </div>
                                        </div>
                                        <div className={s.cardDesc}>
                                            <h5>{item.text}</h5>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={s.action}>
                                <Button
                                    onClick={() => {
                                        setQuestionActive(true);
                                    }}
                                    responsive
                                >
                                    Start the questionnaire
                                </Button>
                                <p className={s.helperText}>This will not affect your credit score</p>
                            </div>

                            <div className={s.basement}>
                                You understand by clicking on the “Start the questionnaire” button above, you agree you
                                are providing "written instructions" to Triton authorizing Triton to obtain your credit
                                profile from any consumer reporting agency which can be used to evaluate your financial
                                history for the purpose of providing you with targeted offers based on your credit
                                profile
                            </div>
                        </>
                    ) : (
                        <div style={{ paddingTop: '40px' }}>
                            <Widget
                                id="tXSGJ5ip"
                                style={{ width: '100%', height: '600px' }}
                                iframeProps={{
                                    src: 'https://form.typeform.com/to/tXSGJ5ip?typeform-medium=embed-snippet?typeform-welcome=0',
                                }}
                            />
                        </div>
                    )}
                </Container>
            </WithHeader>
        </section>
    );
};

export default Quiz;
