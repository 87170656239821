export const CARDS = [
    {
        image: './images/fill.svg',
        title: 'Fill out our form',
        desc: 'Our brief questionnaire analyzes your needs and instantly matches you with the best available options, without affecting your score',
    },
    {
        image: './images/select.svg',
        title: '2. Select appropriate options',
        desc: 'We analyze your questionnaire and match you with the best consolidation options that save you time and money',
    },
    {
        image: './images/solution.svg',
        title: 'Get solution options',
        desc: 'Review your options then select the best consolidation to get approved',
    },
];
