import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HeaderContext } from '../../App';
import SliderSection from '../../components/SliderSection';
import ButtonsGroup from '../../components/ButtonsGroup/ButtonsGroup';
import styles from './ArticlePage.module.scss';
import axios from 'axios';
import { useEffect } from 'react';
import { AppContext } from '../../App';
import ArticleHero from '../../components/ArticleHero/ArticleHero';
import Container from '../../components/Container/Container';

const ArticlePage = () => {
    const { articles } = useContext(AppContext);
    const { blogId } = useParams();
    const { setLoaderBlock } = useContext(HeaderContext);
    const [currentArticle, setCurrentArticle] = useState({});

    useEffect(() => {
        const getArticle = async () => {
            const a = await axios.get(
                `https://us-central1-triton-oracle.cloudfunctions.net/getArticleById?id=${blogId}`
            );
            setCurrentArticle(a.data);
            setLoaderBlock(false);
        };
        setLoaderBlock(true);
        getArticle();
    }, [setLoaderBlock, blogId]);

    function replaceWithBr() {
        return currentArticle?.content?.replace(/\n/g, '<br />');
    }

    return (
        <>
            <ArticleHero
                title={currentArticle.title}
                image={currentArticle.image_url}
                date={currentArticle.published_at}
                chip={currentArticle.section}
            />
            <section className={styles.content}>
                <Container>
                    <div className={styles.holder}>
                        <div className={styles.text} dangerouslySetInnerHTML={{ __html: replaceWithBr() }} />
                        <ButtonsGroup />
                    </div>
                </Container>
            </section>

            <section className={styles.blogArticles}>
                <SliderSection titleBold="articles" titleRest="Related" sliderData={articles} />
            </section>
        </>
    );
};

export default ArticlePage;
