import React from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { ROUTES } from '../../../../constants/constants';
import { fadeInLeft } from '../../../../helpers/utils/animations';
import Container from '../../../Container';
import LinkButton from '../../../LinkButton';
import s from './FinancialJourney.module.scss';

const FinancialJourney = ({ linkTo }) => {
    return (
        <Container className={s.container}>
            <motion.section
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                className={clsx(s.wrap, 'o-hidden')}
            >
                <motion.div className={s['content-left']} variants={fadeInLeft}>
                    <h2 className={s.title}>
                        Dedication to empowering <strong> your financial journey</strong>
                    </h2>
                    <div className={s['subtitle-wrap']}>
                        <p className={s.subtitle}>
                            At Triton, we understand that life happens and have first hand experience of how credit
                            scores can keep you from achieving your goals.
                        </p>
                        <p className={s.subtitle}>
                            By applying our expertise and implementing our comprehensive credit strategies, we can
                            always help get your credit back on track!
                        </p>
                    </div>
                    <LinkButton
                        className={s.cta}
                        linkTo={`/${ROUTES.creditRestorationQuiz}`}
                        text="Read More"
                        variant="primary"
                        hasIcon
                        noHelperText
                    />
                </motion.div>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    className={s['content-right']}
                >
                    <img src="/images/credit-score-solutions/financial-journey.webp" alt="Financial Journey" />
                </motion.div>
            </motion.section>
        </Container>
    );
};

export default FinancialJourney;
