import { useCallback } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { actionsSelector, useCreditRestorationQuizStore } from '../../store';
import { fadeInUp } from '../../../../helpers/utils/animations';
import { ROUTES } from '../../../../constants/constants';
import { CREDIT_RESTORATION_QUIZ_ENTRY_STEPS_TITLES } from './constants';
import Container from '../../../Container';
import IconDone from '../../../ui/Icons/IconDone';
import Button from '../../../ui/Button';
import s from './CreditRestorationQuizEntry.module.scss';

/**
 *
 * @param {{
 *  className?: string;
 *  showCTA?: boolean;
 * }} props
 */
const CreditRestorationQuizEntry = ({ className, showCTA = true }) => {
    const quizActions = useCreditRestorationQuizStore(actionsSelector);

    const navigate = useNavigate();

    const startQuestionnaireClickHandler = useCallback(() => {
        quizActions.cleanUp();

        navigate(`${ROUTES.questionnaire}/${ROUTES.creditScoreInterest}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    return (
        <Container className={clsx(s.wrap, className)}>
            <div className={s.title}>
                <motion.h1 custom={1} variants={fadeInUp}>
                    <strong>Get help</strong> with your credit today
                </motion.h1>
            </div>

            <div className={s.subtitle}>
                <motion.h4 custom={2} variants={fadeInUp}>
                    See your credit credit score and how to improve it
                </motion.h4>
            </div>

            <motion.div
                className={clsx(s.holder, 'steps-wrap')}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
            >
                {CREDIT_RESTORATION_QUIZ_ENTRY_STEPS_TITLES.map((title, index) => (
                    <motion.div custom={index + 1} variants={fadeInUp} className={s.card} key={index}>
                        <div className={s.cardHeader}>
                            <IconDone className={s.icon} />
                            <div className={s.cardTitle}>
                                <h3>
                                    Step&nbsp;<span>#{index + 1}</span>
                                </h3>
                            </div>
                        </div>
                        <div className={s.cardDesc}>
                            <h5>{title}</h5>
                        </div>
                    </motion.div>
                ))}
            </motion.div>
            {showCTA ? (
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
                    <motion.div variants={fadeInUp} className={s.action}>
                        <Button onClick={startQuestionnaireClickHandler} responsive>
                            Start the questionnaire
                        </Button>
                        <p className={s.helperText}>This will not affect your credit score</p>
                    </motion.div>
                </motion.div>
            ) : null}

            <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
                <motion.div variants={fadeInUp} className={s.basement}>
                    You understand by clicking on the &ldquo;Start the questionnaire&rdquo; button above, you agree you
                    are providing &ldquo;written instructions&rdquo; to Triton authorizing Triton to obtain your credit
                    profile from any consumer reporting agency which can be used to evaluate your financial history for
                    the purpose of providing you with targeted offers based on your credit profile
                </motion.div>
            </motion.div>
        </Container>
    );
};

export default CreditRestorationQuizEntry;
