import React, { useState } from 'react';
import styles from './ButtonsGroup.module.scss';

const ButtonsGroup = () => {
    const [copySuccess, setCopySuccess] = useState('Copy link');

    const copyToClip = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url);
        setCopySuccess('Copied');
    };

    return (
        <>
            <hr className={styles.divider} />

            <div className={styles.group}>
                <button onClick={copyToClip} className={styles.button}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.7001 6.46667H13.4001V11.2333C13.4001 11.808 13.1719 12.3591 12.7655 12.7654C12.3592 13.1717 11.8081 13.4 11.2335 13.4H6.4668V14.7C6.4668 15.0448 6.60376 15.3754 6.84756 15.6192C7.09136 15.863 7.42202 16 7.7668 16H14.7001C15.0449 16 15.3756 15.863 15.6194 15.6192C15.8632 15.3754 16.0001 15.0448 16.0001 14.7V7.76667C16.0001 7.42189 15.8632 7.09123 15.6194 6.84744C15.3756 6.60364 15.0449 6.46667 14.7001 6.46667Z"
                            fill="#4C76EB"
                        />
                        <path
                            d="M11.2333 3H4.3C3.58203 3 3 3.58203 3 4.3V11.2333C3 11.9513 3.58203 12.5333 4.3 12.5333H11.2333C11.9513 12.5333 12.5333 11.9513 12.5333 11.2333V4.3C12.5333 3.58203 11.9513 3 11.2333 3Z"
                            fill="#4C76EB"
                        />
                    </svg>
                    {copySuccess}
                </button>
                <a href="https://twitter.com/tritonfinance" target="_blank" rel="noreferrer" className={styles.button}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.3571 4.67043C16.4005 4.60985 16.3364 4.53163 16.2668 4.55858C15.7728 4.75008 15.2565 4.87928 14.7298 4.94295C15.3162 4.59261 15.7687 4.0593 16.0197 3.4279C16.0452 3.36358 15.975 3.30614 15.9144 3.3396C15.3694 3.6406 14.7816 3.85735 14.1709 3.98218C14.1454 3.98741 14.119 3.9787 14.1011 3.95977C13.6429 3.47626 13.0397 3.15434 12.3821 3.04309C11.7108 2.92951 11.0207 3.04197 10.4201 3.36284C9.81955 3.68372 9.34248 4.19485 9.06371 4.81608C8.79865 5.40675 8.72713 6.06518 8.85786 6.69753C8.868 6.74659 8.8296 6.79291 8.77959 6.78981C7.59155 6.71602 6.43078 6.40002 5.36877 5.86085C4.30946 5.32304 3.37141 4.57487 2.61176 3.66247C2.57804 3.62198 2.51414 3.62722 2.48958 3.67383C2.2532 4.12238 2.12944 4.62233 2.12978 5.13045C2.12882 5.63624 2.25294 6.13441 2.49109 6.58062C2.72925 7.02683 3.07404 7.40723 3.49478 7.68795C3.04858 7.67581 2.61109 7.5655 2.21321 7.36551C2.16194 7.33973 2.10043 7.37643 2.1031 7.43376C2.13413 8.10068 2.37897 8.77023 2.80276 9.28392C3.25375 9.83058 3.87972 10.2047 4.57478 10.343C4.30722 10.4244 4.02943 10.4673 3.74978 10.4705C3.59757 10.4687 3.44566 10.4574 3.29493 10.4368C3.23904 10.4292 3.1934 10.4823 3.21297 10.5352C3.42028 11.0958 3.78652 11.585 4.26808 11.9421C4.78292 12.3238 5.40396 12.5356 6.04478 12.548C4.96268 13.3994 3.62669 13.8641 2.24978 13.868C2.10829 13.8684 1.96687 13.8638 1.82579 13.8542C1.74628 13.8488 1.70972 13.9547 1.77825 13.9954C3.11982 14.7915 4.65322 15.2124 6.21728 15.2104C7.37205 15.2224 8.51763 15.0042 9.58711 14.5685C10.6566 14.1328 11.6285 13.4884 12.4462 12.6728C13.2638 11.8573 13.9107 10.887 14.3492 9.81863C14.7876 8.75027 15.0088 7.60525 14.9998 6.45045V6.09058C14.9998 6.0669 15.011 6.04463 15.0299 6.03036C15.5386 5.64626 15.986 5.18768 16.3571 4.67043Z"
                            fill="#4C76EB"
                        />
                    </svg>
                    Twitter
                </a>
                <a
                    href="https://www.facebook.com/Triton-Finance-100574851839565/"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.button}
                >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.65 0.15625C4.73003 0.15625 3.84774 0.521707 3.19722 1.17222C2.54671 1.82274 2.18125 2.70503 2.18125 3.625V5.55625H0.325C0.231802 5.55625 0.15625 5.6318 0.15625 5.725V8.275C0.15625 8.3682 0.231802 8.44375 0.325 8.44375H2.18125V13.675C2.18125 13.7682 2.2568 13.8438 2.35 13.8438H4.9C4.9932 13.8438 5.06875 13.7682 5.06875 13.675V8.44375H6.94144C7.01888 8.44375 7.08637 8.39105 7.10515 8.31593L7.74265 5.76593C7.76928 5.65942 7.68873 5.55625 7.57894 5.55625H5.06875V3.625C5.06875 3.47084 5.12999 3.323 5.239 3.21399C5.348 3.10499 5.49584 3.04375 5.65 3.04375H7.6C7.6932 3.04375 7.76875 2.9682 7.76875 2.875V0.325C7.76875 0.231802 7.6932 0.15625 7.6 0.15625H5.65Z"
                            fill="#4C76EB"
                        />
                    </svg>
                    Facebook
                </a>
                <a
                    href="https://www.linkedin.com/company/tritonfinance/"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.button}
                >
                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.5625 0.40625C1.6823 0.40625 0.96875 1.1198 0.96875 2C0.96875 2.8802 1.6823 3.59375 2.5625 3.59375C3.4427 3.59375 4.15625 2.8802 4.15625 2C4.15625 1.1198 3.4427 0.40625 2.5625 0.40625Z"
                            fill="#4C76EB"
                        />
                        <path
                            d="M1.0625 4.90625C1.01072 4.90625 0.96875 4.94822 0.96875 5V14.75C0.96875 14.8018 1.01072 14.8437 1.0625 14.8437H4.0625C4.11428 14.8437 4.15625 14.8018 4.15625 14.75V5C4.15625 4.94822 4.11428 4.90625 4.0625 4.90625H1.0625Z"
                            fill="#4C76EB"
                        />
                        <path
                            d="M5.9375 4.90625C5.88572 4.90625 5.84375 4.94822 5.84375 5V14.75C5.84375 14.8018 5.88572 14.8437 5.9375 14.8437H8.9375C8.98928 14.8437 9.03125 14.8018 9.03125 14.75V9.5C9.03125 9.12704 9.17941 8.76935 9.44313 8.50563C9.70685 8.24191 10.0645 8.09375 10.4375 8.09375C10.8105 8.09375 11.1681 8.24191 11.4319 8.50563C11.6956 8.76935 11.8438 9.12704 11.8438 9.5V14.75C11.8438 14.8018 11.8857 14.8437 11.9375 14.8437H14.9375C14.9893 14.8437 15.0312 14.8018 15.0312 14.75V8.28519C15.0312 6.46513 13.4483 5.04125 11.6374 5.20587C11.0789 5.25665 10.5267 5.39548 10.0111 5.61646L9.03125 6.0364V5C9.03125 4.94822 8.98928 4.90625 8.9375 4.90625H5.9375Z"
                            fill="#4C76EB"
                        />
                    </svg>
                    LinkedIn
                </a>
            </div>
        </>
    );
};

export default ButtonsGroup;
