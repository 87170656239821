import clsx from 'clsx';
import { ROUTES } from '../../constants/constants';
import Button from '../ui/Button';
import ArrowCircleRightTop from '../ui/Icons/ArrowCircleRightTop';
import s from './LinkButton.module.scss';

const LinkButton = ({
    linkTo,
    blueBlock,
    text,
    hasIcon,
    noHelperText,
    size,
    variant = 'primary',
    responsive = true,
    className,
    innerClassName,
    classNameHelper,
    ref,
    target,
}) => {
    const Icon = variant === 'fourth' ? ArrowCircleRightTop : null;

    return (
        <div className={clsx(s.buttonBlock, s[size], blueBlock ? `${s.white}` : `${s.gray}`, className)}>
            <Button
                href={linkTo ? linkTo : ROUTES.quiz}
                hasIcon={hasIcon}
                target={target}
                Icon={Icon}
                variant={variant}
                size={size}
                className={clsx(s.btn, innerClassName)}
                responsive={responsive}
                ref={ref}
            >
                {text ? text : 'Get options for free'}
            </Button>
            {!noHelperText && (
                <p className={clsx(s.label, blueBlock ? `${s.white}` : `${s.gray}`, classNameHelper)}>
                    This will not affect your credit score
                </p>
            )}
        </div>
    );
};

export default LinkButton;
