export const dataAccordion = [
    {
        id: 1,
        title: 'How does debt consolidation work?',
        info: `There are many different forms of relief for debt that go by the name debt consolidation and all of them have their particular quotes and their own pros. Most commonly, though, when someone says “debt consolidation,” they’re referring to a debt consolidation loan.
    \nWith a debt consolidation loan, you work with a lender to take out a personal loan that is large enough for you to pay off all of your various debts at once. Once you pay all of those debts off, then the only debt payment you have to worry about is paying off the loan. In other words, you’ve consolidated your debt.
    \nDebt consolidation is an attractive way to manage and get out of debt for individuals who owe a lot of debt to multiple different creditors. That’s because, in general, it makes that debt much easier to manage. Instead of making multiple different payments with different interest rates and minimums to a bunch of different creditors, you just have to keep track of a single payment. That makes it easier to keep track of your debt and makes you much less likely to miss a payment and fall behind.
    \nThough there are more forms of debt consolidation than just debt consolidation loans, they all share this quality: a single monthly payment instead of multiple payments each month.
    `,
    },
    {
        id: 2,
        title: 'How do you know if you need debt consolidation?',
        info: `Debt consolidation is a great way for a lot of people to get out of debt. But it’s not for everyone. How do you know if debt consolidation is right for you?
    \nDebt consolidation makes sense if you owe a lot of debt to several different creditors. It should be enough debt that you struggle with keeping up with it but not so much that you’d never be able to get a personal loan in order to pay it off. Large forms of debt like student loans can sometimes be consolidated, but they usually need to be dealt with separately from other forms of debt.
    \nOften, the kind of debt that’s well-suited for debt consolidation is credit card debt. It’s all too possible to open up multiple different credit cards and run them up without realizing what you’re doing. Your main credit card, your emergency credit card, the cards you have with different stores that you opened up for discounts – each one can get out of control if you’re not careful, and dealing with all of these different payments can be a nightmare. In this case, then debt consolidation might be right for you.
    `,
    },
    {
        id: 3,
        title: 'What are the pros and cons of debt consolidation?',
        info: `Even if you do owe money to multiple different creditors, debt consolidation might not be right for you. Here are some basic pros and cons to help to guide your decision.
    \n<h5>Pros</h5> Debt consolidation helps you to stand back and take a breath. By consolidating all of your payments into one, you’ll have a much easier time managing your finances and strategizing how you’ll get out of debt.
    \nDebt consolidation can also help to save you a lot of money in the short term and the long term. In the short term, you might end up paying less each month than you did with multiple different minimum payments, keeping more money in your pocket. In the long term, you might get a more forgiving interest rate and pay less interest over time as well.
    \nDebt consolidation can also help you to see a light at the end of the tunnel when it comes to your debt. When you’re juggling a bunch of minimum payments, it can feel like you’re not making any progress towards becoming debt-free. With debt consolidation, as long as you keep up with your payments, you’ll have a clear path towards eliminating your debt.
    \n<h5>Cons</h5> Debt consolidation, especially debt consolidation loans, can be hard to get if you have bad credit. Potential lenders look to your credit score to determine if you’re safe to lend to, and if they don’t like what they see, they won’t approve your loan application and you’ll be back to square one. Since faltering credit scores are often tied to missed debt payments, a lot of people who could really benefit from debt consolidation end up with bad credit and don’t qualify for decent loans.
    \nDebt consolidation is also a means to an end, not a solution by itself. All you’re doing is making your current debt easier to deal with. If you can’t get your financial house in order and stop using credit, you’ll just end up in the exact same position you are now: heavily indebted to multiple different creditors.
    `,
    },
    {
        id: 4,
        title: 'What’s the difference between an unsecured loan and a secured loan?',
        info: `Debt consolidation loans can come in two different varieties: unsecured loans and secured loans.
    \nWith unsecured loans, the lender is lending to you based on your creditworthiness. They take a look at your credit score and financial history and determine that you’re a good investment for them to take on, so they offer you a decent loan package with a high degree of certainty that you’ll be able to pay it back.
    \nWith secured loans, the lender isn’t quite so certain. Perhaps your credit history isn’t perfect or they see something in your financial picture that gives them reason to doubt that you’ll be able to pay off your loan in a timely manner. So they ask you to “secure” the loan by putting up a piece of collateral, like your car or your home. If you can’t keep up with your payments, they’ll take the collateral instead. It’s security that they’ll at least get something out of the deal.
    \nWhile secured loans can often have lower interest rates than unsecured loans, they’re riskier due to the collateral requirements. If you can’t keep up with your payments for whatever reason, you could end up much worse off than you were before.
    `,
    },
    {
        id: 5,
        title: 'Can debt consolidation save me money?',
        info: `Yes, debt consolidation often saves people quite a bit of money. While it’s not guaranteed that you’ll save money, it does happen pretty often.
    \nOn one hand, you could save money on your monthly payments. Consolidating all of your debt into one payment will make for a fairly hefty sum, but it still might be less than the sum total of your monthly minimum payments. Plus, you’ll be making much quicker progress towards actually paying off your debt.
    \nOn the other hand, debt consolidation packages often have more forgiving interest rates than some credit cards. This lower interest rate means you’ll accrue less total interest every month on the debt that you owe, meaning you’ll pay less in interest over time while you’re paying down your debt.
    \nAll of this means that you’ll actually hang on to more of your income than you were before you consolidated your debt.
    `,
    },
    {
        id: 6,
        title: 'Is debt consolidation guaranteed to work?',
        info: `No, consolidating your debt is no guarantee that you’ll get out of debt, and don’t believe anyone that says otherwise.
    \nYou are what gets you out of debt. Debt consolidation just makes it easier. But it’s up to you to hold back on overspending with credit and get your finances in order.
    \nAn alarmingly high proportion of individuals who seek debt consolidation either don’t realize this or can’t keep up with it. They use debt consolidation to make managing their debt easier. But once all their credit is freed up, they can’t help themselves from using it. They run up a bunch of credit cards just like they did before, and within two years, they’re back to where they started but worse.
    \nIf you can reign in your spending, though, debt consolidation has a good chance of helping you to become more financially stable and debt-free.
    `,
    },
    {
        id: 7,
        title: 'What kind of options do you have for debt consolidation if you have bad credit?',
        info: `As we’ve already discussed, it can be tough to get a debt consolidation loan if you have bad credit. A secured loan might be a possibility, but you might find it to be too risky due to the fact that you’re asked to put up a hefty amount of collateral to qualify.
    \nYou do have options if you have bad credit, though, and they can obtain similar results to debt consolidation. Two of the most popular: debt management and debt settlement
    `,
    },
    {
        id: 8,
        title: 'What’s the difference between a debt consolidation loan and debt management?',
        info: `With debt management, you aren’t actually borrowing money to manage your debt. Instead, you’re working with a third-party to develop a plan for managing your debt and becoming debt-free in a realistic timespan.
    \nOften, debt management can take the form of credit counseling. A credit counselor, often working for a non-profit, takes on your case and counsels you on how to get your finances in order and pay off your debts. They may actually work with your creditors to restructure your debt, or they might just help you to get a better handle on things.
    \nDebt management works best for people who are financially capable of tackling their debts, but just don’t know how. It’s not the same kind of big step that taking out a debt consolidation loan is, but it can still be effective in the right circumstance.
    `,
    },
    {
        id: 9,
        title: 'What’s the difference between a debt consolidation loan and debt settlement?',
        info: `With debt settlement, your goal is to get your creditors to accept less than the total amount of your debt as repayment. In other words, you offer them some level of reimbursement and then ask them to “settle” the rest of your account.
    \nWhile you can approach debt settlement on your own, it’s more likely that you’d work with a professional debt settlement company to complete the process. Debt settlement companies know how to approach creditors and negotiate favorable outcomes on your behalf and can help to make the debt settlement process seem less scary.
    \nWhy would your creditors accept less than what they’re owed? Basically, to ensure that they get anything at all.
    \nWith debt settlement, you often stop making payments to your creditors at all, instead making payments to a savings account managed by your debt settlement agency. Your creditors won’t like this, but hopefully the agency can help you to manage the threatening phone calls and letters that you’re sure to get.
    \nAt the end of the payment period, the debt settlement agency approaches your creditors, offering them the lump sum in the savings account instead of the full repayment of your debt. Often, the creditors accept, happy to get something instead of nothing, and your debt is eliminated.
    `,
    },
    {
        id: 10,
        title: 'How do you identify a trustworthy debt consolidation company?',
        info: `First, trust your gut. If it seems like they’re making promises that are too good to be true, trying to move you through the sales process too quickly and refusing to divulge much information about what they’ll actually do for your case specifically, then you probably can’t trust them.
    \nSecond, do your research. Google the company and see if there have been any legal actions or negative news reports against them. Pay special attention to ratings and reviews from independent third parties like the Better Business Bureau and past customers. These sources often paint a picture of what it’s like to work with a company from the point-of-view of an objective third party.
    `,
    },
];
