import { HUBSPOT_FORMS_GUID, HUBSPOT_FORMS_PORTAL_ID } from '../constants/constants';
import { hubspotFormHttp } from './axios';

/**
 * @param {{
 *  formGuid: string;
 *  portalId: string;
 *  fields: [
 *    { name: 'marketing_id', value: string },
 *    { name: 'debt', value: string },
 *    { name: 'terms', value: string },
 *    { name: 'credit', value: string },
 *    { name: 'financing', value: string },
 *    { name: 'firstname', value: string },
 *    { name: 'lastname', value: string },
 *    { name: 'email', value: string },
 *    { name: 'phone', value: string },
 *    { name: 'state', value: string },
 *    { name: 'city', value: string },
 *    { name: 'address', value: string },
 *    { name: 'zip', value: string },
 *    { name: 'call_back_date', value: string },
 *    { name: 'call_back_time', value: string }
 *  ]
 * }} data
 * @param {*} config
 */
export const createHubspotSubmission = async (data, config) => {
    const response = await hubspotFormHttp.post(
        `/submissions/v3/integration/submit/${HUBSPOT_FORMS_PORTAL_ID}/${HUBSPOT_FORMS_GUID}`,
        data,
        config
    );

    return response.data;
};
