import { Link } from 'react-router-dom';
import { memo, forwardRef } from 'react';
import clsx from 'clsx';
import IconPlay from '../Icons/IconPlay';
import IconArrowRight from '../Icons/IconArrowRight';
import classes from './ButtonFollow.module.scss';

const ButtonFollow = forwardRef(
    (
        {
            children,
            isDefaultLink = false,
            type = 'button',
            icon,
            variant = 'primary',
            size,
            href,
            className,
            onClick,
            reverse,
            responsive = false,
            ...rest
        },
        ref
    ) => {
        const { disabled } = rest;

        if (isDefaultLink && href) {
            return (
                <a
                    ref={ref}
                    href={href}
                    className={clsx(
                        classes.btn,
                        responsive && classes.responsive,
                        reverse && classes.reverse,
                        classes[size],
                        className,
                        disabled && classes.disabled
                    )}
                    rel="noopener noreferrer nofollow noindex"
                    {...rest}
                >
                    <span>{children}</span>
                    <span className={classes.icon}>
                        <IconArrowRight />
                    </span>
                </a>
            );
        }

        if (href) {
            return (
                <Link
                    to={href}
                    ref={ref}
                    className={clsx(
                        classes.btn,
                        responsive && classes.responsive,
                        reverse && classes.reverse,
                        classes[size],
                        className,
                        disabled && classes.disabled
                    )}
                    {...rest}
                >
                    <span>{children}</span>
                    <span className={classes.icon}>
                        <IconArrowRight />
                    </span>
                </Link>
            );
        }

        if (variant === 'play')
            return (
                <button
                    ref={ref}
                    className={clsx(
                        classes.btn,
                        responsive && classes.responsive,
                        reverse && classes.reverse,
                        classes[size],
                        className
                    )}
                    type={type}
                    onClick={onClick}
                    {...rest}
                >
                    <span>{children}</span>
                    <span className={classes.icon}>
                        <IconPlay />
                    </span>
                </button>
            );

        return (
            <button
                ref={ref}
                className={clsx(
                    classes.btn,
                    responsive && classes.responsive,
                    reverse && classes.reverse,
                    classes[size],
                    className
                )}
                type={type}
                onClick={onClick}
                {...rest}
            >
                <span>{children}</span>
                <span className={classes.icon}>
                    <IconArrowRight />
                </span>
            </button>
        );
    }
);

ButtonFollow.displayName = 'ButtonFollow';

export default memo(ButtonFollow);
