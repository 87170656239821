export const RESULTS = [
    {
        num: '10000',
        plus: true,
        text: 'Customers we’ve helped',
    },
    {
        num: '$100M',
        plus: true,
        text: 'Consumer debt resolved',
    },
    {
        num: '$10M',
        plus: true,
        text: 'Consolidation loans matched',
    },
    {
        num: '4.8',
        star: true,
        text: 'Excellent client rating',
    },
];
