import React from 'react';
import ButtonsGroup from '../../components/ButtonsGroup/ButtonsGroup';
import styles from './AgreementsPage.module.scss';

const Terms = () => {
    return (
        <div className={styles.content}>
            <h1 className="h1">Terms of Use</h1>
            <h4 className="h4">Effective Date: January 1st, 2023</h4>

            <h3 className="h3">Acceptance of Terms</h3>

            <p>
                Welcome to the Triton Finance website ("Website"). These Terms of Use ("Terms") govern your access to
                and use of the Website. By accessing or using the Website, you agree to be bound by these Terms. If you
                do not agree to these Terms, please refrain from using the Website.
            </p>

            <h3 className="h3">Use of the Website</h3>
            <p>
                a. Eligibility: You must be at least 18 years old to use the Website. By using the Website, you
                represent and warrant that you are 18 years of age or older.
                <br />
                b. Permitted Use: The Website is provided for informational purposes only. You may use the Website to
                learn about our debt consolidation services, access educational resources, and contact us for further
                information. Any other use of the Website without our prior written consent is strictly prohibited.
                <br />
                c. Account Registration: Some features or services on the Website may require you to create an account.
                You are responsible for providing accurate and complete information during the registration process and
                for maintaining the security of your account credentials. You are also responsible for all activities
                that occur under your account.
                <br />
                d. Prohibited Activities: You agree not to engage in any activities that may:
                <br />
                - Violate any applicable laws, regulations, or third-party rights.
                <br />
                - Interfere with or disrupt the Website's functionality or the experiences of other users.
                <br />
                - Attempt to gain unauthorized access to the Website or any related systems or networks.
                <br />
                - Transmit any viruses, malware, or harmful code to the Website or its users.
                <br />
            </p>

            <h3 className="h3">Intellectual Property Rights</h3>
            <p>
                a. Ownership: The Website and all its content, including text, graphics, logos, images, videos, and
                software, are owned or licensed by Triton Finance and are protected by intellectual property laws. You
                acknowledge that these rights are valid and enforceable.
                <br />
                b. Limited License: Triton Finance grants you a limited, non-exclusive, non-transferable license to
                access and use the Website for personal, non-commercial purposes. You may not reproduce, modify,
                distribute, or create derivative works based on the Website or its content without our prior written
                consent.
                <br />
                c. Trademarks: All trademarks, service marks, and logos displayed on the Website are the property of
                Triton Finance or their respective owners. You may not use these marks without our prior written
                permission or the permission of the respective owners.
            </p>

            <h3>Privacy</h3>
            <p>
                Your privacy is important to us. Please review our Privacy Policy, which explains how we collect, use,
                and protect your personal information in connection with the Website. By using the Website, you consent
                to the practices described in our Privacy Policy.
            </p>

            <h3 className="h3">Disclaimer of Warranties</h3>
            <p>
                a. General: The Website and its content are provided on an "as-is" and "as available" basis. We make no
                warranties or representations, express or implied, regarding the accuracy, completeness, reliability, or
                suitability of the Website or its content for any purpose.
                <br />
                b. Third-Party Content: The Website may include content provided by third parties. We do not endorse or
                assume any responsibility for such content, and we make no warranties or representations regarding its
                accuracy, completeness, or legality.
                <br />
                c. Limitation of Liability: In no event shall Triton Finance or its affiliates be liable for any direct,
                indirect, incidental, consequential, or punitive damages arising out of or in connection with your use
                of the Website or its content. This limitation applies to any damages, regardless of whether they arise
                from contract, tort, strict liability, or other legal theory, even if we have been advised of the
                possibility of such damages.
            </p>

            <h3 className="h3">Links to Third-Party Websites</h3>
            <p>
                The Website may contain links to third-party websites or services. We do not endorse or assume any
                responsibility for the content, privacy practices, or terms of use of these third-party websites. You
                access them at your own risk.
            </p>

            <h3 className="h3">Termination</h3>
            <p>
                We may, at our sole discretion, suspend or terminate your access to the Website without prior notice if
                we believe you have violated these Terms or any applicable laws or regulations. We may also modify,
                suspend, or discontinue the Website or its features at any time without liability.
            </p>

            <h3 className="h3">Governing Law and Jurisdiction</h3>
            <p>
                These Terms shall be governed by and construed in accordance with the laws of [Insert Jurisdiction]. Any
                disputes arising out of or in connection with these Terms or your use of the Website shall be submitted
                to the exclusive jurisdiction of the courts of [Insert Jurisdiction].
            </p>

            <h3 className="h3">Contact Us</h3>
            <p>If you have any questions, concerns, or requests regarding these Terms, please contact us at:</p>
            <p>
                Triton Consulting LLC
                <br />
                Address: 3500 S Dupont Hwy, Dover, DE 19901
                <br />
                Email: info@triton.finance
                <br />
            </p>
            <p>By using the Triton Finance Website, you signify your acceptance of these Terms of Use.</p>
            <ButtonsGroup />
        </div>
    );
};

export default Terms;
