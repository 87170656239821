import { motion } from 'framer-motion';
import { fadeInLeft } from '../../../../../../helpers/utils/animations';
import Typography from '../../../../../Typography';
import s from './CreditScoreSolutionsOfferCard.module.css';

const CreditScoreSolutionsOfferCard = ({ stepNumber, title, description, imageName }) => {
    return (
        <motion.li
            key={stepNumber}
            className={s.wrap}
            variants={fadeInLeft}
            transition={{
                duration: 0.3,
                delay: 0.2 * stepNumber,
                ease: 'easeInOut',
            }}
        >
            <img
                className={s.image}
                width={120}
                height={80}
                src={`/images/credit-score-solutions/${imageName}.svg`}
                alt="Service"
            />
            <Typography className={s.step} variant="text-small" asChild>
                <span>{`Step ${stepNumber}`}</span>
            </Typography>
            <Typography className={s.title} variant="heading-h4" asChild>
                <span>{title}</span>
            </Typography>
            <Typography className={s.subtitle} variant="text-small" asChild>
                <p>{description}</p>
            </Typography>
        </motion.li>
    );
};

export default CreditScoreSolutionsOfferCard;
