import clsx from 'clsx';
import IconDone from '../ui/Icons/IconDone';
import Container from '../Container/Container';
import styles from './TheeColumnSection.module.scss';
import { fadeInLeft, fadeInUp } from '../../helpers/utils/animations';
import { motion } from 'framer-motion';

const TheeColumnSection = ({ titleBold, titleRest, subtitle, columnsData, variant }) => {
    return (
        <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
            <Container>
                <motion.h2 custom={1} variants={fadeInLeft}>
                    <strong>{titleBold}</strong> {titleRest}
                </motion.h2>
                <motion.h5 custom={2} variants={fadeInLeft} className={clsx(styles.h5, 'h2-subtitle')}>
                    {subtitle}
                </motion.h5>

                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    className={clsx(styles.holder, styles[variant])}
                >
                    {columnsData?.length &&
                        columnsData.map((item, i) => (
                            <motion.div custom={i + 1} variants={fadeInUp} key={item.id}>
                                <div className={styles.content} key={item.id}>
                                    {item.iconDone && <IconDone className={styles.icon} />}
                                    {item.image && (
                                        <picture className={styles.image}>
                                            <source srcSet={item.imageMob} media="(max-width: 479px)" />
                                            <img src={item.image} alt={item.title} />
                                        </picture>
                                    )}
                                    <h4>{item.title}</h4>
                                    <p>{item.text}</p>
                                </div>
                            </motion.div>
                        ))}
                </motion.div>
            </Container>
        </motion.div>
    );
};

export default TheeColumnSection;
