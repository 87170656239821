import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { middlewares } from '../../store/middlewares';
import { CREDIT_RESTORATION_QUIZ_STEPS, CREDIT_RESTORATION_QUIZ_TIMELINE_OPTIONS } from './constants';
import {
    CREDIT_RESTORATION_CREDIT_GOALS_OPTIONS,
    CREDIT_RESTORATION_CREDIT_SCORE_INTEREST_OPTIONS,
    ROUTES,
} from '../../constants/constants';

export const CREDIT_RESTORATION_QUIZ_INITIAL_CONFIG = {
    // NOTE: ???
    scrollTo: 0,
    currentStepNumber: CREDIT_RESTORATION_QUIZ_STEPS[ROUTES.understandingNeeds].step,
    currentStepLabel: CREDIT_RESTORATION_QUIZ_STEPS[ROUTES.understandingNeeds].label,
    currentStepId: CREDIT_RESTORATION_QUIZ_STEPS[ROUTES.understandingNeeds].step_name,
    stepOneActiveSubStep: 1,
    stepTwoActiveSubStep: -1,
    stepThreeActiveSubStep: -1,
    showPane: false,
};

export const CREDIT_RESTORATION_CREDIT_SCORE_INTEREST_STEP_STATE = {
    creditScoreInterest: CREDIT_RESTORATION_CREDIT_SCORE_INTEREST_OPTIONS[0],
};

export const CREDIT_RESTORATION_QUIZ_INITIAL_UNDERSTANDING_NEEDS_STEP_STATE = {
    creditGoals: CREDIT_RESTORATION_CREDIT_GOALS_OPTIONS[0],
};

export const CREDIT_RESTORATION_QUIZ_INITIAL_TIMELINE_STEP_STATE = {
    timeline: CREDIT_RESTORATION_QUIZ_TIMELINE_OPTIONS[0],
};

export const CREDIT_RESTORATION_QUIZ_INITIAL_PERSONAL_INFO_STEP_STATE = {
    // State 1
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    isNameDataStepValid: false,
    // State 2
    state: undefined,
    city: undefined,
    address: '',
    zip: '',
    isAddressStepValid: false,
};

export const CREDIT_RESTORATION_QUIZ_INITIAL_STATE = {
    ...CREDIT_RESTORATION_QUIZ_INITIAL_CONFIG,
    // Understanding needs step
    ...CREDIT_RESTORATION_QUIZ_INITIAL_UNDERSTANDING_NEEDS_STEP_STATE,
    // Timeline step
    ...CREDIT_RESTORATION_QUIZ_INITIAL_TIMELINE_STEP_STATE,
    // Personal info step
    ...CREDIT_RESTORATION_QUIZ_INITIAL_PERSONAL_INFO_STEP_STATE,
};

const createStore = persist(
    (set) => {
        return {
            ...CREDIT_RESTORATION_QUIZ_INITIAL_STATE,
            /**
             * @param {keyof typeof CREDIT_RESTORATION_QUIZ_INITIAL_STATE} key
             * @param {(typeof CREDIT_RESTORATION_QUIZ_INITIAL_STATE)[keyof typeof CREDIT_RESTORATION_QUIZ_INITIAL_STATE]} value
             */
            setField(key, value) {
                set(
                    (state) => {
                        state[key] = value;
                        return state;
                    },
                    false,
                    {
                        type: 'quiz/setField',
                        payload: {
                            key,
                            value,
                        },
                    }
                );
            },
            /**
             * @param {Partial<typeof CREDIT_RESTORATION_QUIZ_INITIAL_STATE>} values
             */
            setFields(values) {
                set(
                    (state) => {
                        state = Object.assign(state, values);
                        return state;
                    },
                    false,
                    {
                        type: 'quiz/setFields',
                        payload: values,
                    }
                );
            },
            cleanUp() {
                set(CREDIT_RESTORATION_QUIZ_INITIAL_STATE, false, {
                    type: 'quiz/cleanUp',
                });
            },
        };
    },
    {
        name: 'credit-restoration-quiz-store',
        storage: createJSONStorage(() => {
            return sessionStorage;
        }),
    }
);

export const useCreditRestorationQuizStore = create()(middlewares('credit-restoration-quiz-store', immer(createStore)));

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof CREDIT_RESTORATION_QUIZ_INITIAL_CONFIG}
 */
export const configSelector = (state) => {
    return {
        scrollTo: state.scrollTo,
        currentStepNumber: state.currentStepNumber,
        currentStepLabel: state.currentStepLabel,
        currentStepId: state.currentStepId,
        subSteps: state.subSteps,
        stepOneActiveSubStep: state.stepOneActiveSubStep,
        stepTwoActiveSubStep: state.stepTwoActiveSubStep,
        stepThreeActiveSubStep: state.stepThreeActiveSubStep,
        showPane: state.showPane,
    };
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof CREDIT_RESTORATION_CREDIT_SCORE_INTEREST_STEP_STATE}
 */
export const creditScoreInterestSelector = (state) => {
    return {
        creditScoreInterest: state.creditScoreInterest,
    };
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof CREDIT_RESTORATION_QUIZ_INITIAL_UNDERSTANDING_NEEDS_STEP_STATE}
 */
export const understandingNeedsStepSelector = (state) => {
    return {
        creditGoals: state.creditGoals,
    };
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof CREDIT_RESTORATION_QUIZ_INITIAL_TIMELINE_STEP_STATE}
 */
export const timelineStepSelector = (state) => {
    return {
        timeline: state.timeline,
    };
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof CREDIT_RESTORATION_QUIZ_INITIAL_PERSONAL_INFO_STEP_STATE}
 */
export const personalInfoStepSelector = (state) => {
    return {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        phone: state.phone,
        isNameDataStepValid: state.isNameDataStepValid,
        state: state.state,
        city: state.city,
        address: state.address,
        zip: state.zip,
        isAddressStepValid: state.isAddressStepValid,
    };
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {typeof CREDIT_RESTORATION_QUIZ_INITIAL_STATE}
 */
export const allFieldsSelector = (state) => {
    const { setField: _, setFields: __, cleanUp: ___, ...rest } = state;

    return rest;
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {Pick<ReturnType<typeof createStore>, 'cleanUp' | 'setField' | 'setFields'>}
 */
export const actionsSelector = (state) => {
    return {
        cleanUp: state.cleanUp,
        setField: state.setField,
        setFields: state.setFields,
    };
};
