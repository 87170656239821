export const VALUES = [
    {
        number: 5,
        text: 'years on the market',
    },
    {
        number: 65,
        text: 'financial experts',
    },
    {
        number: '10K',
        text: 'happy customers',
    },
];
