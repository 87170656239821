export const SOLVE_ITEMS = [
    {
        image: './images/svg/debt-sm.svg',
        imageMob: './images/svg/debt-sm-mob.svg',
        title: 'Debt',
        titleBold: 'Consolidation',
        desc: 'Learn how you can combine your debts into one monthly payment to lower your interest, save monthly, and shorten your payoff time',
    },
    {
        image: './images/svg/loan-sm.svg',
        imageMob: './images/svg/loan-sm-mob.svg',
        title: 'Personal',
        titleBold: 'Loans',
        desc: 'Our network of over 300 lenders can match you with the right personal loan to consolidate high-interest debt',
    },
    {
        image: './images/svg/lines-sm.svg',
        imageMob: './images/svg/lines-sm-mob.svg',
        title: 'Lines',
        titleBold: 'of Credit',
        desc: "We'll help you find a variety of credit lines to save money and restructure your debt at a lower interest rate",
    },
];
