export const whyTriton = [
    {
        id: 'w1',
        title: 'Nationwide Lender Network',
        text: 'With over 300 loan providers, we have one of the largest lending networks in the US',
        iconDone: true,
    },
    {
        id: 'w2',
        title: 'Expert Guidance',
        text: 'Our expert consultants have years of experience to help ensure you have the best terms for your loan approval',
        iconDone: true,
    },
    {
        id: 'w3',
        title: 'No Cost To You',
        text: 'All of our services are provided at no cost to you',
        iconDone: true,
    },
];
