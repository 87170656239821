// !!! NOTE: This is component, not a HOC
import Header from '../../components/Header/Header';

const WithHeader = ({ children, themeDark, launchButtonText, launchButtonHref, isDropDownNav, isAnimation }) => {
    return (
        <>
            <Header
                themeDark={themeDark}
                launchButtonText={launchButtonText}
                launchButtonHref={launchButtonHref}
                isDropDownNav={isDropDownNav}
                isAnimation={isAnimation}
            />
            {children}
        </>
    );
};

export default WithHeader;
