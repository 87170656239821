import Typography from '../../../../../Typography';
import Loader from '../../../../../ui/loader';
import s from './CreditRestorationQuizLoadingOptions.module.css';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../../../../../../constants/constants';
import { useCreditRestorationArrayToHubspot } from '../../hooks/useCreditRestorationArrayToHubspot';
import { actionsSelector, useCreditRestorationQuizStore } from '../../../../store';
import { useEffect } from 'react';

const CreditRestorationQuizLoadingOptions = () => {
    const quizActions = useCreditRestorationQuizStore(actionsSelector);

    const {
        data: arrayToHubspotResponse,
        isFetching,
        isError,
        isSuccess,
        error,
    } = useCreditRestorationArrayToHubspot();

    useEffect(() => {
        quizActions.setField('currentStepNumber', 6);
    }, [quizActions]);

    if (isFetching) {
        return (
            <div className={s.inner}>
                <Loader />
                <div className={s['title-wrap']}>
                    <h2 className="h2">Analyzing your options</h2>
                    <div className={s.subtitle}>
                        <Typography variant="helper-text" asChild>
                            <h5>Preparing solutions...</h5>
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }

    if ((isSuccess && !arrayToHubspotResponse?.creditScore?.max) || (isError && error?.name !== 'AbortError')) {
        return <Navigate to={ROUTES.error} replace />;
    }

    if (arrayToHubspotResponse.creditScore) {
        return <Navigate to={ROUTES.success} replace />;
    }
};

export default CreditRestorationQuizLoadingOptions;
