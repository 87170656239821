export const CREDIT_RESTORATION_STATS = [
    {
        value: '580',
        text: 'AVG Initial Score',
        icon: 'gaming',
    },
    {
        value: '23',
        text: 'AVG Point Increase per Month',
        icon: 'whats-new',
    },
    {
        value: '730',
        text: 'AVG Client Score After Program',
        icon: 'time',
    },
];
