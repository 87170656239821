import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { actionsSelector, personalInfoStepSelector, useCreditRestorationQuizStore } from '../../../../../store';
import { quizPersonalInfoAddressSchema } from '../schemas';

export const useQuizPersonalInfoAddressForm = () => {
    const { state, city, address, zip } = useCreditRestorationQuizStore(personalInfoStepSelector);
    const quizActions = useCreditRestorationQuizStore(actionsSelector);

    const {
        control,
        formState: { isValid },
        ...rest
    } = useForm({
        defaultValues: {
            state,
            city,
            address,
            zip,
        },
        resolver: zodResolver(quizPersonalInfoAddressSchema),
        mode: 'onChange',
    });

    const stateController = useController({
        control,
        name: 'state',
    });

    const cityController = useController({
        control,
        name: 'city',
    });

    const addressController = useController({
        control,
        name: 'address',
    });

    const zipController = useController({
        control,
        name: 'zip',
    });

    useEffect(() => {
        quizActions.setField('isAddressStepValid', isValid && !!cityController.field.value);
    }, [cityController.field.value, isValid, quizActions]);

    return {
        stateController,
        cityController,
        addressController,
        zipController,
        ...rest,
    };
};
