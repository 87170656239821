export const CHART_DEFAULT_HEIGHT = 400;
export const CHART_DEFAULT_MARGIN = 24;
export const CHART_VALUES_DOMAIN = [350, 850];
export const CHART_AXIS_COLOR = '#A9A9A9';
export const CHART_LINE_COLOR = '#1B4489';
export const CREDIT_SCORE_SOLUTION_CHART_COLOR = '#2D76F5';
export const CHART_AXIS_DEFAULT_TICKS_AMOUNT = 6;

export const CHART_EMPTY_CELL = {
    name: '',
    value: undefined,
};
