import IconFacebook from '../components/ui/Icons/IconFacebook';
import IconLInkedin from '../components/ui/Icons/IconLInkedin';
import IconInstagram from '../components/ui/Icons/IconInstagram';

/**
 * Environment variables
 */
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const HUBSPOT_FORMS_PORTAL_ID = process.env.REACT_APP_HUBSPOT_FORMS_PORTAL_ID;
export const HUBSPOT_FORMS_GUID = process.env.REACT_APP_HUBSPOT_FORMS_GUID;

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL;

export const ONE_SECOND = 1_000;
export const ONE_MINUTE = 60 * ONE_SECOND;

export const SOCIALS = [
    { icon: IconInstagram, href: 'https://www.instagram.com/triton.finance/' },
    {
        icon: IconFacebook,
        href: 'https://www.facebook.com/Triton-Finance-100574851839565/',
    },
    {
        icon: IconLInkedin,
        href: 'https://www.linkedin.com/company/tritonfinance/',
    },
];

export const DEFAULT_TRANSITION = { type: 'linear', duration: 0.3 };

export const COOKIE_MARKETING_ID = 'triton_marketing_id';
export const COOKIE_AFFILIATE_ID = 'triton_aff_id';

export const SPONSOR_MARKETING_ID_PREFIX = 'spn';
export const PARTNER_MARKETING_ID_PREFIX = 'prtnr';
export const AFFILIATE_MARKETING_ID_PREFIX = 'aff';

export const EXTRACT_NUMBER_REGEX = /\D+/g;

export const NUMBERS_IN_STRING_REGEX = /\d+(\.\d+)?/g;

export const CREDIT_REPAIR_UCA_URL = 'https://uca-credit.phnmn.com/quiz';

export const ROUTES = {
    home: '/',
    //
    medicalDebt: '/medical-debt',
    //
    debtSolutions: '/debt-solutions',
    //
    about: '/about',
    //
    help: '/help',
    //
    options: '/options',
    //
    newQuiz: '/questionnaire',
    //
    blog: '/blog',
    // Blog subroutes
    blogId: ':blogId',
    //
    policy: '/policy',
    //
    terms: '/terms',
    //
    quiz: '/quiz',
    // Quiz steps
    understandingNeeds: 'understanding-needs',
    personalInfo: 'personal-info',
    annualIncome: 'annual-income',
    // Credit score solutions
    creditScoreSolutions: 'credit-score-solutions',
    //
    reviewOptions: 'review-options',
    // Review options flows
    //
    default: 'default',
    // global review option subflows flows
    withCreditScore: 'with-credit-score',
    withCreditScoreAndDebtAmount: 'with-credit-score-and-debt-amount',
    //
    seeOffers: 'see-offers',
    //
    withInstructions: 'with-instructions',
    // Review options with instructions flows
    withChartAndCreditScore: 'with-chart-and-credit-score',
    withChartAndCreditScoreAndDebtAmount: 'with-chart-and-credit-score-and-debt-amount',
    //
    scheduleConsultation: 'schedule-consultation',
    // Schedule consultation flows
    withCallNow: 'with-call-now',
    //
    creditRestoration: 'credit-restoration',
    // Credit restoration flows
    creditScoreInterest: 'credit-score-interest',
    creditGoals: 'credit-goals',
    motivation: 'motivation',
    //
    // Credit restoration quiz
    creditRestorationQuiz: 'credit-restoration-quiz',
    //
    timeline: 'timeline',
    address: 'address',
    //  Other global routes
    questionnaire: 'questionnaire',
    success: 'success',
    error: 'error',
};

export const CREDIT_RESTORATION_CREDIT_GOALS_OPTIONS = [
    'Buy a car',
    'Buy or refinance a home',
    'Consolidate debts at a lower interest rate',
    'Other',
];

export const CREDIT_RESTORATION_CREDIT_SCORE_INTEREST_OPTIONS = [
    'Yes, I want to improve my credit and get better financing in the future',
    'No thank you, I`m happy with my current credit',
];

export const US_CODE = 'US';
